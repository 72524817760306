import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navigation from './Navigation';
import Footer from './Footer';

enum Tabs {
    Basic,
    Planets,
    Dasha,
    Lal_Kitab,
    Charts
  }

const Kundli = () => {
    const [selectedTabs, setSelectedTabs] = useState<Tabs>(Tabs.Basic);
    const location = useLocation();
    const { responses } = location.state as any;
    const { chartImages } = location.state as any;

    const firstResponseData = responses[0].data;
    const secondResponseData = responses[1].data;
    const thirdResponseData = responses[2].data;
    const forthResponseData = responses[3].data;
    const fifthResponseData = responses[4].data;
    const sixthResponseData = responses[5].data;
    const seventhResponseData = responses[6].data;
    const eighthResponseData = responses[7].data;
    const chartImageUrls = chartImages.slice(8).map((response) => response.imageUrl);

    const renderRows1 = () => {
        return Object.keys(firstResponseData).map((key) => (
          <tr key={key}>
            <td className='capital' data-label="Title">{key}</td>
            <td className='capital' data-label="Description">{firstResponseData[key]}</td>
          </tr>
        ));
      };

      const renderRows3 = () => {
        return Object.keys(thirdResponseData).map((key) => (
          <tr key={key}>
            <td className='capital' data-label={key}>{key}</td>
            <td className='capital' data-label="Due Date">{thirdResponseData[key]}</td>
          </tr>
        ));
      };

      const renderRows6 = () => {
        return (
            <>
                    
                {sixthResponseData.map((planet) => (
                <tr key={planet.id}>
                    <td className='capital' data-label="Planet">{planet.name}</td>
                    <td className='capital' data-label="Full Degree">{planet.fullDegree.toFixed(3)}</td>
                    <td className='capital' data-label="Is Retro">{planet.isRetro}</td>
                    <td className='capital' data-label="Sign">{planet.sign}</td>
                    <td className='capital' data-label="Sign Lord">{planet.signLord}</td>
                    <td className='capital' data-label="Nakshatra">{planet.nakshatra}</td>
                    <td className='capital' data-label="Nakshatra Lord">{planet.nakshatraLord}</td>
                    <td className='capital' data-label="Nakshatra Pad">{planet.nakshatra_pad}</td>
                    <td className='capital' data-label="House">{planet.house}</td>
                </tr>
                ))}
            </>
        );
      };


      const renderRows4 = () => {
        return (
            <>
                <tr className='desktop'>
                    <th className='capital' data-label="Sign ID">Sign ID</th>
                    <th className='capital' data-label="Sign Name">Sign Name</th>
                    <th className='capital' data-label="Duration">Duration</th>
                    <th className='capital' data-label="Start Date">Start Date</th>
                    <th className='capital' data-label="End Date">End Date</th>
                </tr>
                {forthResponseData.map((sign) => (
                    <tr key={sign.sign_id}>
                        <td className='capital' data-label="Sign ID">{sign.sign_id}</td>
                        <td className='capital' data-label="Sign Name">{sign.sign_name}</td>
                        <td className='capital' data-label="Duration">{sign.duration}</td>
                        <td className='capital' data-label="Start Date">{sign.start_date}</td>
                        <td className='capital' data-label="End Date">{sign.end_date}</td>
                    </tr>
                ))}
            </>
        );
    };

    const renderDebtDetails = () => {
        return seventhResponseData.map((debt) => (
            <div className="col-lg-12 d-flex justify-content-center col-sm-12" key={debt.debt_name}>
                <div className="astro-cont3">
                    <h2>{debt.debt_name}</h2>
                    <p><span className='text-md font-bold headings'>Indication : </span> {debt.indications}</p>
                    <p><span className='headings'>Events : </span> {debt.events}</p>
                </div>
            </div>
        ));
    };
    

    const renderRows8 = () => {
        return (
            <>
                <tr className='desktop'>
                    <th className='capital' data-label="Planet">Planet</th>
                    <th className='capital' data-label="Rashi">Rashi</th>
                    <th className='capital' data-label="Soya">Soya</th>
                    <th className='capital' data-label="Position">Position</th>
                    <th className='capital' data-label="Nature">Nature</th>
                </tr>
                {eighthResponseData.map((planet) => (
                    <tr key={planet.planet}>
                        <td className='capital' data-label="Planet">{planet.planet}</td>
                        <td className='capital' data-label="Rashi">{planet.rashi}</td>
                        <td className='capital' data-label="Soya">{planet.soya.toString()}</td>
                        <td className='capital' data-label="Position">{planet.position}</td>
                        <td className='capital' data-label="Nature">{planet.nature}</td>
                    </tr>
                ))}
            </>
        );
    };
    
    
      

  return (
    <> 
      <div style={{paddingBottom:"100px", marginTop:"0px"}} className="container yoyo">
      <h1 style={{ textTransform: 'capitalize', color:"orange", fontSize:"50px" }}>Kundli</h1>
        <hr style={{
          width:"100%",
          position:"relative",
          zIndex:"-1",
          border: 0,
          height: "1px",
          backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0))"
        }} />
        <div className="detailings {activeTab === 'Today'}">
          <div className="tabas">
            <div onClick={() => setSelectedTabs(Tabs.Basic)} className={`tabs ${selectedTabs === Tabs.Basic ? 'activeTab' : ''}`}>
              Basic
            </div>
            <div onClick={() => setSelectedTabs(Tabs.Planets)} className={`tabs ${selectedTabs === Tabs.Planets ? 'activeTab' : ''}`}>
              Planets
            </div>
            <div onClick={() => setSelectedTabs(Tabs.Dasha)} className={`tabs ${selectedTabs === Tabs.Dasha ? 'activeTab' : ''}`}>
              Dasha
            </div>
            <div onClick={() => setSelectedTabs(Tabs.Lal_Kitab)} className={`tabs ${selectedTabs === Tabs.Lal_Kitab ? 'activeTab' : ''}`}>
              Lal Kitab
            </div>
            <div onClick={() => setSelectedTabs(Tabs.Charts)} className={`tabs ${selectedTabs === Tabs.Charts ? 'activeTab' : ''}`}>
              Charts
            </div>
          </div>
        </div>
        {selectedTabs === Tabs.Basic && (
            <div className="row">
                <hr style={{
                    width:"100%",
                    position:"relative",
                    zIndex:"-1",
                    border: 0,
                    height: "1px",
                    marginBottom:"40px",
                    backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0))"
                }} />
                <div className="col-lg-6 col-sm-12">
                    <h1 style={{color:"#000"}}>Panchang Details</h1>
                    <table className='basic'>
                    <tbody>
                        {renderRows1()}
                    </tbody>
                    </table>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <h1 style={{color:"#000"}}>Astro Details</h1>
                    <table className='basic'>
                    <tbody>
                        {renderRows3()}
                    </tbody>
                    </table>
                </div>
            </div>
        )}
        {selectedTabs === Tabs.Planets && (
            <div className="row">
                <hr style={{
                    width:"100%",
                    position:"relative",
                    zIndex:"-1",
                    border: 0,
                    height: "1px",
                    marginBottom:"40px",
                    backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0))"
                }} />
                <div className="col-lg-12 col-sm-12">
                    <h1 style={{color:"#000"}}>Planet Details</h1>
                    <table className='basic'>
                    <tbody>
                        {renderRows6()}
                    </tbody>
                    </table>
                </div>
            </div>
        )}
        {selectedTabs === Tabs.Dasha && (
            <div className="row">
                <hr style={{
                    width:"100%",
                    position:"relative",
                    zIndex:"-1",
                    border: 0,
                    height: "1px",
                    marginBottom:"40px",
                    backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0))"
                }} />
                <div className="col-lg-12 col-sm-12">
                    <h1 style={{color:"#000"}}>Maha Dasha</h1>
                    <table className='basic'>
                    <tbody>
                        {renderRows4()}
                    </tbody>
                    </table>
                </div>
            </div>
        )}
        {selectedTabs === Tabs.Lal_Kitab && (
            <div className="row">
                <hr style={{
                    width:"100%",
                    position:"relative",
                    zIndex:"-1",
                    border: 0,
                    height: "1px",
                    marginBottom:"40px",
                    backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0))"
                }} />
                <div className="col-lg-12 col-sm-12">
                    <h1 style={{color:"#000"}}>Planet Chart</h1>
                        <table className='basic'>
                            <tbody>
                                {renderRows8()}
                            </tbody>
                    </table>
                </div>
                <h1 style={{color:"#000", marginTop:"40px"}}>Lal Kitab Debt</h1>
                <hr />
                {renderDebtDetails()}
            </div>
        )}
        {selectedTabs === Tabs.Charts && (
        <div className="row">
            {chartImages.map((imageUrl, index) => (
            <div key={index} className="col-lg-3 col-md-4 col-sm-6">
                <img src={imageUrl} alt={`Chart ${index}`} style={{ width: '100%', height: 'auto' }} />
            </div>
            ))}
        </div>
        )}

      </div>
    </>
  );
}

export default Kundli;
