import React, { useState, ReactElement } from 'react';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';

const Deletion = () => {
  
  return (
    <> 
        <main>
          <Navigation activePage='' />
          <div style={{marginTop:"100px", marginBottom:"50px"}} className="pivcc col-lg-8 col-12 mx-auto">
            <form className="custom-form ebook-download-form bg-white shadow" action="#" method="post" role="form">
                <div className="text-center mb-5">
                    <h1 style={{color:"#000"}} className="mb-1">Astropath Account Deletion</h1>
                </div>
                <p className="mb-5">This form is created By Astropath Team, to help you delete your account data from our servers. Once the form is submitted our team will delete your selected type of data from our server in 3 days. Please note this process is irreversible and once data is deleted it cannot be reversed.</p>
                <div className="ebook-download-form-body">
                    <div className="input-group mb-4">
                        <input type="text" name="ebook-form-name" id="ebook-form-name" className="form-control" aria-label="ebook-form-name" aria-describedby="basic-addon1" placeholder="Your Name" required />
                    </div>

                    <div className="input-group mb-4">
                        <input type="email" name="ebook-email" id="ebook-email" pattern="[^ @]*@[^ @]*" className="form-control" placeholder="Your email" aria-label="ebook-form-email" aria-describedby="basic-addon2" required />

                    </div>
                    <div className="input-group mb-4">
                        <input type="number" name="email" id="email" className="form-control" placeholder="Your Phone Number" aria-label="ebook-form-email" aria-describedby="basic-addon2" required />

                    </div>
                    <div className="input-group mb-4">
                        <textarea name="ebook-email" id="ebook-email" className="form-control" placeholder="Reason for deletion" aria-label="ebook-form-email" aria-describedby="basic-addon2" required />
                    </div>
                    <div className="col-lg-8 col-md-10 col-8 mx-auto">
                        <button style={{width:"100%"}} type="submit" className="btn">Submit</button>
                    </div>
                </div>
            </form>
          </div>
          <Footer />
        </main>
    </>
  )
}

export default Deletion;