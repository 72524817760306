/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import axios from 'axios';
import Cookies from 'js-cookie';
import axiosInstance from '../../../../app/helpers/axiosInstance'
import toast, { Toaster } from 'react-hot-toast';
import defaulti from '../../../assets/card/favi.png'

type Props = {
  profile: any;
}
const HeaderUserMenu: React.FC<Props> = ({ profile }) => {
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate();
  const user_type = Cookies.get('user_type');

  const handleCustomerLogout = () => {
    Cookies.remove('isLoggedIn');
    Cookies.remove('userID');
    Cookies.remove('token');
    window.location.href = '/'
  };

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary py-3 fs-6 w-auto'
      data-kt-menu='true'
    >
      <Toaster />
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {profile.profile_photo ? (
              <img alt='Profile' src={profile.profile_photo} />
            ) : (
              <img alt='Default Profile' src={defaulti} />
            )}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bold d-flex align-items-center fs-5'>
              {profile ? profile.name : 'User'}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {profile.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item '>
        <Link to={'/customer/astrologers'} className='menu-link px-5'>
          Astrologers
        </Link>
        <Link to={'/customer/kundliform'} className='menu-link px-5'>
          Kundli
        </Link>
        <Link to={'/customer/numeroform'} className='menu-link px-5'>
          Numerology
        </Link>
        <Link to={'/customer/chats'} className='menu-link px-5'>
          Chats
        </Link>
        <Link to={'/customer/panchang'} className='menu-link px-5'>
          Panchang
        </Link>
        <Link to={'/customer/transactions'} className='menu-link px-5'>
          Transactions
        </Link>
        <Link to={'/customer/orders'} className='menu-link px-5'>
          Orders
        </Link>
        <Link to={'/customer/support'} className='menu-link px-5'>
          Support
        </Link>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item '>
        <a onClick={handleCustomerLogout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
