import React, { FC, useState, useRef, ChangeEvent } from 'react';
import { KTIcon } from '../../../helpers';
import { Field, ErrorMessage, Formik, Form } from 'formik';
import axios from 'axios';
import toast from 'react-hot-toast';
import ClearIcon from '@mui/icons-material/Delete'
import { RadioButtonUnchecked } from '@mui/icons-material';

const InviteUsers: FC = () => {
  const inputStyle = {
    border: '1px solid #d3d3d3',
    borderRadius: '10px',
    padding: '10px',
    paddingLeft: '20px',
    width: '90%',
    boxSizing: 'border-box',
  };
  const inputStyle1 = {
    border: '1px solid #d3d3d3',
    borderRadius: '10px',
    padding: '10px',
    paddingLeft: '20px',
    width: '45%',
    boxSizing: 'border-box',
  };
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    name: '',
    profile_photo: 'https://firebasestorage.googleapis.com/v0/b/zegocloudvideocall-b50bd.appspot.com/o/userprofile%2F1712393187617?alt=media&token=818422d4-3e47-4e24-bdf0-7f44be0f2f0b',
    dob: '',
    city: '',
    bio: '',
    working_on_another_platform: true,
    platform_name: 'Astropath',
    where_you_learned_astrology: '',
    main_source_of_income: '',
    where_did_you_hear_astropath: 'Facebook',
    expected_income: '',
    how_many_hours_you_can_contribute: '',
    did_anybody_refered_you_to_astropath: false,
    experience: '',
    expertise: [],
    gender: '',
    featured: false,
    aadhar: '',
    pan: '',
    passbook_photo: ''
  })

  const aadharFileInputRef = useRef<HTMLInputElement | null>(null)
  const [aadharImageUrl, setAadharImageUrl] = useState('')

  const panFileInputRef = useRef<HTMLInputElement | null>(null)
  const [panImageUrl, setPanImageUrl] = useState('')

  const passbookFileInputRef = useRef<HTMLInputElement | null>(null)
  const [passbookImageUrl, setPassbookImageUrl] = useState('')

const handleFileUpload = async (file) => {
  try {
    const formData = new FormData();
    formData.append('image', file);
    const response = await axios.post('https://dev.astropath.co.in/backend/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const fileUrl = response.data.imageUrl;
    return fileUrl;
  } catch (error) {
    console.error('Error uploading file:', error);
    return '';
  }
}


  const handleAadharSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = async (e) => {
        if (e.target) {
          setAadharImageUrl(e.target.result as string)

          try {
            const imageLink = await handleFileUpload(file)
            setFormData({ ...formData, aadhar: imageLink })
          } catch (error) {
            console.error('Error uploading image:', error)
          }
        }
      }
      reader.readAsDataURL(file)
    }
  }
  

  const handleAadharUpload = () => {
    if (aadharFileInputRef.current) {
      aadharFileInputRef.current.click()
    }
  }

  const handlePassbookSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = async (e) => {
        if (e.target) {
          setPassbookImageUrl(e.target.result as string)

          try {
            const imageLink = await handleFileUpload(file)
            setFormData({ ...formData, passbook_photo: imageLink })
          } catch (error) {
            console.error('Error uploading image:', error)
          }
        }
      }
      reader.readAsDataURL(file)
    }
  }
  

  const handlePassbookUpload = () => {
    if (passbookFileInputRef.current) {
      passbookFileInputRef.current.click()
    }
  }

  const handlePanSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = async (e) => {
        if (e.target) {
          setPanImageUrl(e.target.result as string)

          try {
            const imageLink = await handleFileUpload(file)
            setFormData({ ...formData, pan: imageLink })
          } catch (error) {
            console.error('Error uploading image:', error)
          }
        }
      }
      reader.readAsDataURL(file)
    }
  }

  const handlePanUpload = () => {
    if (panFileInputRef.current) {
      panFileInputRef.current.click()
    }
  }

  const handleFieldChange = (fieldName, value) => {
    setFormData({ ...formData, [fieldName]: value })
  }

  const handleSaveClick = async (values) => {
    try {
      const selectedExpertise = Object.keys(values)
        .filter((key) => key.startsWith('expertise_') && values[key])
        .map((key) => key.replace('expertise_', ''));
      const updatedFormData = { ...formData, expertise: selectedExpertise };
  
      const response = await axios.post('https://dev.astropath.co.in/super_admin/backend/create_astrologer', updatedFormData);
      if (response.status === 200) {
        toast.success('Astrologer created successfully');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error('Error creating astrologer:', error);
      toast.error('Error creating astrologer');
    }
  };

  return (
    <div className='modal fade' id='kt_modal_invite_friends' aria-hidden='true'>
      <div className='modal-dialog mw-850px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 pt-0 pb-15'>
            <div className='mb-13'>
              <h1 className='mb-3'>Onboard an Astrologer</h1>
              <div className='text-muted fw-bold fs-5'>Please fill out the details below</div>
              <hr
                style={{
                  width: '100%',
                  border: 0,
                  height: '0.5px',
                  margin: '15px 0px',
                  backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0))',
                }}
              />
              <Formik
                initialValues={{}}
                onSubmit={handleSaveClick}
              >
                {() => (
                  <Form className='py-5 mt-2 px-auto w-100 d-flex flex-row flex-wrap' noValidate id={`kt_create_account_form`}>
                    <div className='px-auto d-flex flex-column'>
                      <div className='px-auto w-100 d-flex flex-row flex-wrap'>
                        <div className='w-50 d-flex flex-column mb-5'>
                          <label className='form-label fs-5 required'>Name</label>
                          <Field type='text' onChange={(e) => handleFieldChange('name', e.target.value)} name='name' className='form-control form-control-lg form-control-solid' style={inputStyle} />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='name' />
                          </div>
                        </div>
                        <div className='w-50 d-flex flex-column mb-5'>
                          <label className='form-label fs-5 required'>Email</label>
                          <Field type='email' name='email' onChange={(e) => handleFieldChange('email', e.target.value)} className='form-control form-control-lg form-control-solid' style={inputStyle} />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='email' />
                          </div>
                        </div>
                        <div className='w-50 d-flex flex-column my-5'>
                          <label className='form-label fs-5 required'>Phone</label>
                          <Field type='tel' name='phone' onChange={(e) => handleFieldChange('phone', e.target.value)} className='form-control form-control-lg form-control-solid' style={inputStyle} />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='phone' />
                          </div>
                        </div>
                        <div className='w-50 d-flex flex-column my-5'>
                          <label className='form-label fs-5 required'>City</label>
                          <Field type='text' name='city' onChange={(e) => handleFieldChange('city', e.target.value)} className='form-control form-control-lg form-control-solid' style={inputStyle} />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='city' />
                          </div>
                        </div>
                        <div className='w-50 d-flex flex-column my-5'>
                          <label className='form-label fs-5 required'>Where You Learned Astrology</label>
                          <Field type='text' onChange={(e) => handleFieldChange('where_you_learned_astrology', e.target.value)} name='where_you_learned_astrology' className='form-control form-control-lg form-control-solid' style={inputStyle} />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='where_you_learned_astrology' />
                          </div>
                        </div>
                        <div className='w-50 d-flex flex-column my-5'>
                          <label className='form-label fs-5 required'>Main Source of Income</label>
                          <Field type='text' onChange={(e) => handleFieldChange('main_source_of_income', e.target.value)} name='main_source_of_income' className='form-control form-control-lg form-control-solid' style={inputStyle} />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='main_source_of_income' />
                          </div>
                        </div>
                        <div className='w-50 d-flex flex-column my-5'>
                          <label className='form-label fs-5 required'>Expected Income (In Thousands)</label>
                          <Field type='number' onChange={(e) => handleFieldChange('expected_income', e.target.value)} name='expected_income' className='form-control form-control-lg form-control-solid' style={inputStyle} />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='expected_income' />
                          </div>
                        </div>
                        <div className='w-50 d-flex flex-column my-5'>
                          <label className='form-label fs-5 required'>How Many Hours Can You Contribute</label>
                          <Field type='number' onChange={(e) => handleFieldChange('how_many_hours_you_can_contribute', e.target.value)} name='how_many_hours_you_can_contribute' className='form-control form-control-lg form-control-solid' style={inputStyle} />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='how_many_hours_you_can_contribute' />
                          </div>
                        </div>
                        <div className='w-50 d-flex flex-column my-5'>
                          <label className='form-label fs-5 required'>Experience (In Years)</label>
                          <Field type='text' onChange={(e) => handleFieldChange('experience', e.target.value)} name='experience' className='form-control form-control-lg form-control-solid' style={inputStyle} />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='experience' />
                          </div>
                        </div>
                        <div className='w-50 d-flex flex-column my-5'>
                          <label className='form-label fs-5 required'>Gender</label>
                          <Field as='select' onChange={(e) => handleFieldChange('gender', e.target.value)} name='gender' className='form-control form-control-lg form-control-solid' style={inputStyle}>
                            <option value=''>Select Gender...</option>
                            <option value='Male'>Male</option>
                            <option value='Female'>Female</option>
                          </Field>
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='gender' />
                          </div>
                        </div>
                        <div className='w-100 d-flex flex-column my-5'>
                          <label className='form-label fs-5 required'>D.O.B</label>
                          <Field type='text' placeholder='MM-DD-YYYY' onChange={(e) => handleFieldChange('dob', e.target.value)} name='dob' className='form-control form-control-lg form-control-solid' style={inputStyle1} />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='dob' />
                          </div>
                        </div>
                        <div className='w-50 d-flex flex-column gap-4 my-5'>
                          <label className='form-label fs-5 required'>Expertise</label>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-check my-3">
                                <Field 
                                  type='checkbox' 
                                  name='expertise_Tarot' 
                                  value='Tarot' 
                                  id='expertiseCheckbox_Tarot' 
                                  className="form-check-input" 
                                />
                                <label htmlFor='expertiseCheckbox_Tarot' className="form-check-label">Tarot</label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-check my-3">
                                <Field 
                                  type='checkbox' 
                                  name='expertise_Numerology' 
                                  value='Numerology' 
                                  id='expertiseCheckbox_Numerology' 
                                  className="form-check-input" 
                                />
                                <label htmlFor='expertiseCheckbox_Numerology' className="form-check-label">Numerology</label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-check my-3">
                                <Field 
                                  type='checkbox' 
                                  name='expertise_KP' 
                                  value='KP' 
                                  id='expertiseCheckbox_KP' 
                                  className="form-check-input" 
                                />
                                <label htmlFor='expertiseCheckbox_KP' className="form-check-label">KP</label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-check my-3">
                                <Field 
                                  type='checkbox' 
                                  name='expertise_Crystal' 
                                  value='Crystal' 
                                  id='expertiseCheckbox_Crystal' 
                                  className="form-check-input" 
                                />
                                <label htmlFor='expertiseCheckbox_Crystal' className="form-check-label">Crystal</label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-check my-3">
                                <Field 
                                  type='checkbox' 
                                  name='expertise_Palmistry' 
                                  value='Palmistry' 
                                  id='expertiseCheckbox_Palmistry' 
                                  className="form-check-input" 
                                />
                                <label htmlFor='expertiseCheckbox_Palmistry' className="form-check-label">Palmistry</label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-check my-3">
                                <Field 
                                  type='checkbox' 
                                  name='expertise_Vedic' 
                                  value='Vedic' 
                                  id='expertiseCheckbox_Vedic' 
                                  className="form-check-input" 
                                />
                                <label htmlFor='expertiseCheckbox_Vedic' className="form-check-label">Vedic</label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-check my-3">
                                <Field 
                                  type='checkbox' 
                                  name='expertise_Nadal' 
                                  value='Nadal' 
                                  id='expertiseCheckbox_Nadal' 
                                  className="form-check-input" 
                                />
                                <label htmlFor='expertiseCheckbox_Nadal' className="form-check-label">Nadal</label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-check my-3">
                                <Field 
                                  type='checkbox' 
                                  name='expertise_Psychic' 
                                  value='Psychic' 
                                  id='expertiseCheckbox_Psychic' 
                                  className="form-check-input" 
                                />
                                <label htmlFor='expertiseCheckbox_Psychic' className="form-check-label">Psychic</label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-check my-3">
                                <Field 
                                  type='checkbox' 
                                  name='expertise_Face Reading' 
                                  value='Face Reading' 
                                  id='expertiseCheckbox_Face Reading' 
                                  className="form-check-input" 
                                />
                                <label htmlFor='expertiseCheckbox_Face Reading' className="form-check-label">Face Reading</label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-check my-3">
                                <Field 
                                  type='checkbox' 
                                  name='expertise_Vastu' 
                                  value='Vastu' 
                                  id='expertiseCheckbox_Vastu' 
                                  className="form-check-input" 
                                />
                                <label htmlFor='expertiseCheckbox_Vastu' className="form-check-label">Vastu</label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-check my-3">
                                <Field 
                                  type='checkbox' 
                                  name='expertise_Nadi' 
                                  value='Nadi' 
                                  id='expertiseCheckbox_Nadi' 
                                  className="form-check-input" 
                                />
                                <label htmlFor='expertiseCheckbox_Nadi' className="form-check-label">Nadi</label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-check my-3">
                                <Field 
                                  type='checkbox' 
                                  name='expertise_Muhurat' 
                                  value='Muhurat' 
                                  id='expertiseCheckbox_Muhurat' 
                                  className="form-check-input" 
                                />
                                <label htmlFor='expertiseCheckbox_Muhurat' className="form-check-label">Muhurat</label>
                              </div>
                            </div>
                          </div>
                          
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='expertise' />
                          </div>
                        </div>
                        <div className='w-50 d-flex flex-column my-5'>
                          <label className='form-label fs-5 required'>Bio</label>
                          <Field as='textarea' onChange={(e) => handleFieldChange('bio', e.target.value)} name='bio' rows={10} className='form-control form-control-lg form-control-solid' style={inputStyle} />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='bio' />
                          </div>
                        </div>
                        <div className='w-50 d-flex gap-8'>
                          <label className='form-label fs-5 '>Featured</label>
                          <label className='switch'>
                            <Field
                              type='checkbox'
                              name='featured'
                              className='form-check-input'
                              onChange={(e) => handleFieldChange('featured', e.target.value)}
                            />
                            <span className='slider'></span>
                          </label>
                        </div>
                        <hr
                          style={{
                            width: '100%',
                            border: 0,
                            height: '0.5px',
                            margin: '15px 0px',
                            backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0))',
                          }}
                        />
                        <div className='w-50 d-flex gap-4 my-5'>
                          <div style={{ width: '90%' }}>
                            <h6 className='required'>Aadhar Image</h6>
                            {aadharImageUrl ? (
                              <div
                                style={{
                                  border: '4px dotted gray',
                                  width: '100%',
                                  height: 250,
                                  borderRadius: '10px',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  marginTop: 20,
                                }}
                              >
                                <div
                                  onClick={() => setAadharImageUrl('')}
                                  style={{
                                    justifyContent: 'flex-end',
                                    position: 'relative',
                                    backgroundColor: 'white',
                                    padding: 7,
                                    borderRadius: 50,
                                    left: "10px",
                                    width:"35px",
                                    zIndex:"1",
                                    cursor: 'pointer',
                                  }}
                                >
                                  <ClearIcon style={{ color: 'red' }} />
                                </div>
                                <img
                                  src={aadharImageUrl}
                                  alt='Uploaded Image'
                                  style={{ maxWidth: '100%', maxHeight: '100%', position:"relative", marginTop:"-35px" }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  border: '4px dotted gray',
                                  width: '100%',
                                  height: 250,
                                  borderRadius: '10px',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  paddingTop: 20,
                                  marginTop: 20,
                                }}
                              >
                                <h4 className='mx-10 mt-10'>Aadhar Image</h4>
                                <button
                                  type='button'
                                  onClick={handleAadharUpload}
                                  className='btn btn-lg btn-success me-3 mt-7'
                                  style={{ justifyContent: 'flex-end', backgroundColor: '#843C14' }}
                                >
                                  <span style={{color:"#fff"}} className='indicator-label'>Select Files</span>
                                </button>
                                <p className='text-bold pt-5 fs-9' style={{ color: '#555555' }}>
                                  Supports Image only.
                                </p>
                                <input
                                  type='file'
                                  ref={aadharFileInputRef}
                                  style={{ display: 'none' }}
                                  accept="image/*"
                                  onChange={handleAadharSelect}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='w-50 d-flex gap-4 my-5'>
                          <div style={{ width: '90%' }}>
                            <h6 className='required'>Pan Card</h6>
                            {panImageUrl ? (
                              <div
                                style={{
                                  border: '4px dotted gray',
                                  width: '100%',
                                  height: 250,
                                  borderRadius: '10px',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  marginTop: 20,
                                }}
                              >
                                <div
                                  onClick={() => setPanImageUrl('')}
                                  style={{
                                    justifyContent: 'flex-end',
                                    position: 'relative',
                                    backgroundColor: 'white',
                                    padding: 7,
                                    borderRadius: 50,
                                    left: "10px",
                                    width:"35px",
                                    zIndex:"1",
                                    cursor: 'pointer',
                                  }}
                                >
                                  <ClearIcon style={{ color: 'red' }} />
                                </div>
                                <img
                                  src={panImageUrl}
                                  alt='Uploaded Image'
                                  style={{ maxWidth: '100%', maxHeight: '100%', position:"relative", marginTop:"-35px" }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  border: '4px dotted gray',
                                  width: '100%',
                                  height: 250,
                                  borderRadius: '10px',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  paddingTop: 20,
                                  marginTop: 20,
                                }}
                              >
                                <h4 className='mx-10 mt-10'>Pan Card</h4>
                                <button
                                  type='button'
                                  onClick={handlePanUpload}
                                  className='btn btn-lg btn-success me-3 mt-7'
                                  style={{ justifyContent: 'flex-end', backgroundColor: '#843C14' }}
                                >
                                  <span style={{color:"#fff"}} className='indicator-label'>Select Files</span>
                                </button>
                                <p className='text-bold pt-5 fs-9' style={{ color: '#555555' }}>
                                  Supports Image only.
                                </p>
                                <input
                                  type='file'
                                  ref={panFileInputRef}
                                  style={{ display: 'none' }}
                                  accept="image/*"
                                  onChange={handlePanSelect}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='w-50 d-flex gap-4 my-5'>
                          <div style={{ width: '90%' }}>
                            <h6 className='required'>Pass Book / Cancelled Cheque</h6>
                            {passbookImageUrl ? (
                              <div
                                style={{
                                  border: '4px dotted gray',
                                  width: '100%',
                                  height: 250,
                                  borderRadius: '10px',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  marginTop: 20,
                                }}
                              >
                                <div
                                  onClick={() => setPassbookImageUrl('')}
                                  style={{
                                    justifyContent: 'flex-end',
                                    position: 'relative',
                                    backgroundColor: 'white',
                                    padding: 7,
                                    borderRadius: 50,
                                    left: "10px",
                                    width:"35px",
                                    zIndex:"1",
                                    cursor: 'pointer',
                                  }}
                                >
                                  <ClearIcon style={{ color: 'red' }} />
                                </div>
                                <img
                                  src={passbookImageUrl}
                                  alt='Uploaded Image'
                                  style={{ maxWidth: '100%', maxHeight: '100%', position:"relative", marginTop:"-35px" }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  border: '4px dotted gray',
                                  width: '100%',
                                  height: 250,
                                  borderRadius: '10px',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  paddingTop: 20,
                                  marginTop: 20,
                                }}
                              >
                                <h4 className='mx-10 mt-10'>Passbook / Cancelled Cheque</h4>
                                <button
                                  type='button'
                                  onClick={handlePassbookUpload}
                                  className='btn btn-lg btn-success me-3 mt-7'
                                  style={{ justifyContent: 'flex-end', backgroundColor: '#843C14' }}
                                >
                                  <span style={{color:"#fff"}} className='indicator-label'>Select Files</span>
                                </button>
                                <p className='text-bold pt-5 fs-9' style={{ color: '#555555' }}>
                                  Supports Image only.
                                </p>
                                <input
                                  type='file'
                                  ref={passbookFileInputRef}
                                  style={{ display: 'none' }}
                                  accept="image/*"
                                  onChange={handlePassbookSelect}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    <button style={{ background: '#7D3807', color: '#fff' }} type='submit' className='btn mt-8'>
                      Save and Add
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { InviteUsers };
