import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

interface OfferModalProps {
  show: boolean;
  onHide: () => void;
  bannerUrl: string; // Add this line
}

const OfferModal: React.FC<OfferModalProps> = ({ show, onHide, bannerUrl }) => {
    const [bannerImageUrl, setBannerImageUrl] = useState<string | null>(null);

    useEffect(() => {
        const storedImageUrl = localStorage.getItem('bannerImageUrl');
        if (!storedImageUrl) {
            fetchRandomBanner();
        } else {
            setBannerImageUrl(storedImageUrl);
        }
    }, []);

    const fetchRandomBanner = async () => {
        try {
            const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdXBlcl9hZG1pbl91c2VyX2lkIjpudWxsLCJhc3Ryb2xvZ2VyX3VzZXJfaWQiOm51bGwsImN1c3RvbWVyX3VzZXJfaWQiOiI2NWU2YmE4MDRkNzA4NjUxZjc4NTVmNjQiLCJpYXQiOjE3MTQ1ODYyNDB9.QI3_XqN6wu7cSxQoGWdHkwB8KmDhYmUbEC-x87xKFT0";
            const response = await axios.get('https://dev.astropath.co.in/customer/customer/banner', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            const randomIndex = Math.floor(Math.random() * response.data.data.length);
            const randomBanner = response.data.data[randomIndex];
            localStorage.setItem('bannerImageUrl', randomBanner.photos[0]);
            setBannerImageUrl(randomBanner.photos[0]);
        } catch (error) {
            console.error('Error fetching random banner:', error);
        }
    };

    useEffect(() => {
        if (bannerUrl) {
            setBannerImageUrl(bannerUrl);
        }
    }, [bannerUrl]);

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Body>
                {bannerImageUrl && (
                    <>
                        <img src={bannerImageUrl} alt="Offer Banner" className="img-fluid" />
                        <Button
                            style={{ top: "-20px", right: "-20px", borderRadius: "50%", width: "40px", height: "40px" }}
                            variant="secondary"
                            className="position-absolute d-flex justify-content-center align-items-center m-2"
                            onClick={onHide}
                        >
                            <span aria-hidden="true">&times;</span>
                        </Button>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default OfferModal;
