import { useState, useEffect, useRef, ChangeEvent } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
import Navigation from './Navigation';
import Footer from './Footer';
import Cookies from 'js-cookie';

const inputStyles = {
  borderRadius: '20px',
  boxShadow: 'none',
  border: '1px solid #f1991c',
  padding: '13px 40px 13px 20px',
  outline: '0'
};

const Supportform: React.FC = () => {
    const photoFileInputRef = useRef<HTMLInputElement | null>(null)
    const [photoImageUrl, setPhotoImageUrl] = useState('')

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        description: '',
        photo: ''
    });

    const handlePhotoSelect = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                if (e.target) {
                    setPhotoImageUrl(e.target.result as string); // Yahaan pe nayi image ka URL set kiya ja raha hai.
                    
                    try {
                        const imageLink = await handleFileUpload(file);
                        setFormData({ ...formData, photo: imageLink }); // Yahaan pe formData mein nayi image ka URL update kiya ja raha hai.
                    } catch (error) {
                        console.error('Error uploading image:', error);
                    }
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleFileUpload = async (file) => {
        try {
            const formData = new FormData();
            formData.append('image', file);
            const response = await axios.post('https://dev.astropath.co.in/backend/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const fileUrl = response.data.imageUrl;
            return fileUrl;
        } catch (error) {
            console.error('Error uploading file:', error);
            return '';
        }
    }

    const handleSubmit = async (values: any) => {
        const token = Cookies.get('token');
        try {
            if (photoImageUrl) {
                const formDataWithPhoto = { ...values, photo: formData.photo };
                const response = await axios.post('https://dev.astropath.co.in/customer/customer/create_support_ticket', formDataWithPhoto, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                toast.success('Support ticket created successfully');
            } else {
                toast.error('Please select a photo');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to create support ticket');
        }
    };
    


  return (
    <> 
    <Toaster />
      <main>
        <div className="col-lg-8 col-12 mx-auto">
          <Formik
            initialValues={{ }}
            onSubmit={handleSubmit}
          >
            <Form className="custom-form ebook-download-form bg-white shadow">
              <div className="text-center mb-5">
                <h1 style={{color:"#000"}} className="mb-1">Support Ticket</h1>
              </div>
              <p className="mb-5">
              If you have any questions, concerns, or issues regarding our services or products, we're here to help you. Please fill out the form below to create a support ticket. Our team will promptly attend to your request and provide you with the assistance you need.</p>
              <div className="ebook-download-form-body">
                <div style={{width:"90%"}} className="form-group mb-4">
                    <label className='form-label fs-5 required'>Full Name</label>
                    <Field style={inputStyles} type="text" name="name" className="form-control" placeholder="Full Name" />
                    <ErrorMessage name="name" component="div" className="error" />
                </div>
                <div style={{width:"90%"}} className="form-group mb-4">
                    <label className='form-label fs-5 required'>Email</label>
                    <Field style={inputStyles} type="text" name="email" className="form-control" placeholder="Email" />
                    <ErrorMessage name="email" component="div" className="error" />
                </div>
                <div style={{width:"90%"}} className="form-group mb-4">
                    <label className='form-label fs-5 required'>Description</label>
                    <Field style={inputStyles} as='textarea' type="text" name="description" className="form-control" placeholder="Description" />
                    <ErrorMessage name="description" component="div" className="error" />
                </div>
                <div className='w-75 mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    Screenshot
                  </label>
                  <input
                    style={inputStyles}
                    type='file'
                    ref={photoFileInputRef}
                    className='form-control'
                    id='photoImageUrl'
                    name='photoImageUrl'
                    accept='image/*'
                    onChange={handlePhotoSelect}
                    required
                  />
                </div>
                
                <div className="col-lg-8 col-md-10 col-8 mx-auto">
                  <button style={{width:"100%"}} type="submit" className="btn">Submit</button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </main>
    </>
  );
}

export default Supportform;
