import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import indiaFlag from '../../_metronic/assets/card/flag.jpg';
import Cookies from 'js-cookie';
import OtpInput from 'react-otp-input';
import toast, { Toaster } from 'react-hot-toast';
import '../pages/Forgot_password/forgot.css'

const Navigation = ({ activePage }) => {
  const [show, setShow] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [orderId, setOrderId] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseVerifyModal = () => setShowVerifyModal(false);
  const handleShowVerifyModal = () => setShowVerifyModal(true);

  const toggleMenu = () => {
    const mobileMenu = document.getElementById('mobile-menu');
    if (mobileMenu) {
      mobileMenu.style.display = mobileMenu.style.display === 'flex' ? 'none' : 'flex';
      mobileMenu.classList.toggle('hamburger-open');
    }
  };

  const sendOTP = async (phoneNumber: string) => {
    try {
      const formattedPhoneNumber = '+91' + phoneNumber;
      const response = await fetch('https://dev.astropath.co.in/backend/customer/otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone: formattedPhoneNumber }),
      });
    
      if (response.status === 200) {
        const data = await response.json();
        const orderIdFromResponse = data.payload.orderId; 
        setOrderId(orderIdFromResponse); 
        handleShowVerifyModal();
      } else {
        console.error('Error sending OTP. Status code:', response.status);
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
    
  };


  const verifyOTP = async (otp: string) => {
    try {
      const formattedPhoneNumber = '+91' + phoneNumber;
      const response = await fetch('https://dev.astropath.co.in/backend/customer/otpVerify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ otp, phone: formattedPhoneNumber, orderId }),
      });
  
      if (response.status === 200) {
        const data = await response.json();
        const token = data.jwt_token;
        const userId = data.existingCustomer._id;
        handleCloseVerifyModal();
        Cookies.set('isLoggedIn', 'true', { expires: 7 });
        Cookies.set('token', token, { expires: 15 });
        Cookies.set('userId', userId, { expires: 15 });
        setTimeout(() => {
          window.location.href = '/customer/astrologers';
        }, 400);
      } else {
        const errorData = await response.json();
        if (errorData.response.reason === "OTP Expired!") {
          toast.error("OTP has expired. Please request a new OTP.")
        } else {
          console.error('Error verifying OTP. Status code:', response.status);
        }
      }
    } catch (error) {
      toast.error("Server Error");
    }
  };

  const handleResendOTP = async (formikProps) => {
    try {
      const response = await fetch('https://dev.astropath.co.in/backend/customer/resendOtp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderId: orderId }),
      });
      const data = await response.json();
      if (data.success) {
        for (let i = 1; i <= 6; i++) {
          formikProps.setFieldValue(`digit${i}`, '');
        }
      } else {
        console.error('Error resending OTP:', data.message);
      }
    } catch (error) {
      console.error('Error resending OTP:', error);
    }
  };


  return (
    <div id="nav">
      <Toaster />
      <Link to='/' className="part11">
        <img className="logo" src="/images/logotext.webp" alt="logo" />
      </Link>

      <div className="part21 as_menu">
        <Link to="/" className={activePage === '/' ? 'active' : ''}>Home</Link>
        <Link to="/about" className={activePage === '/about' ? 'active' : ''}>About</Link>
        <Link to="/astrologers" className={activePage === '/astrologers' ? 'active' : ''}>Astrologers</Link>
        <Link to="/blogs" className={activePage === '/blogs' ? 'active' : ''}>Blogs</Link>
        <Link to="/contact" className={activePage === '/contact' ? 'active' : ''}>Contact</Link>
        <button onClick={handleShow} className="as_btn1">Login</button>
      </div>

      <i className="ri-menu-3-fill hamburger" onClick={toggleMenu}></i>
      <div id="mobile-menu" className="mobile-menu">
        <Link to="/" className={activePage === '/' ? 'active-link' : ''}>Home</Link>
        <Link to="/about" className={activePage === '/about' ? 'active-link' : ''}>About</Link>
        <Link to="/astrologers" className={activePage === '/astrologers' ? 'active-link' : ''}>Astrologers</Link>
        <Link to="/blogs" className={activePage === '/blogs' ? 'active-link' : ''}>Blogs</Link>
        <Link to="/contact" className={activePage === '/contact' ? 'active-link' : ''}>Contact</Link>
        <a onClick={handleShow}>Login</a>
      </div>

      <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton style={{ backgroundColor: '#7D3807', color: '#fff' }}>
              <Modal.Title>Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{ phoneNumber: '' }}
                onSubmit={(values, actions) => {
                  setPhoneNumber(values.phoneNumber);
                  sendOTP(values.phoneNumber);
                  handleClose();
                  actions.setSubmitting(false);
                  handleShowVerifyModal();
                }}
              >
                {(formikProps) => (
                  <Form onSubmit={formikProps.handleSubmit}>
                    <Form.Group controlId="formBasicPhoneNumber">
                      <Form.Label>Phone Number</Form.Label>
                      <div style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', width: '40px', height: 'auto', display:"flex", alignItems:"center", justifyContent:"start"}}>
                          <img src={indiaFlag} alt="India Flag"  />
                          <p className='mx-1' style={{margin:"auto 0"}}>+91</p>
                        </div>
                        
                        <Form.Control
                          type="tel"
                          name="phoneNumber"
                          maxLength={10}
                          value={formikProps.values.phoneNumber}
                          onChange={(event) => {
                            const phoneNumber = event.target.value.replace(/\D/g, '');
                            formikProps.setFieldValue('phoneNumber', phoneNumber.slice(0, 10));
                          }}
                          onBlur={formikProps.handleBlur}
                          style={{ border: '1px solid #7D3807', borderRadius: '5px', paddingLeft: '85px', marginBottom: "10px", fontSize:"16px" }} 
                        />

                      </div>
                      <Form.Text className="text-muted fs-6">
                        We'll never share your phone number with anyone else.
                      </Form.Text>
                    </Form.Group>
                    <Button
                      className='my-3'
                      variant="primary"
                      type="submit"
                      style={{ backgroundColor: '#7D3807', float: "right", borderColor: '#7D3807', color: "#fff" }}
                    >
                      Send OTP
                    </Button>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
        </Modal>
        <Modal show={showVerifyModal} onHide={handleCloseVerifyModal} centered>
        <Modal.Header closeButton style={{ backgroundColor: '#7D3807', color: '#fff' }}>
            <Modal.Title>Verify OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Formik
          initialValues={{ otp: '' }}
          onSubmit={(values, actions) => {
            const otp = values.otp;
            verifyOTP(otp);
            actions.setSubmitting(false);
          }}
        >
          {(formikProps) => (
            <Form onSubmit={formikProps.handleSubmit}>
              <h6 style={{ color: "#000", margin: "10px 0px 20px 0px" }}>Please enter OTP sent to your mobile number.</h6>
              <div style={{ display: 'flex', justifyContent: 'center', gap: "5px", margin: "10px 0px" }}>
                <OtpInput
                  value={formikProps.values.otp}
                  onChange={(otp) => {
                    const numericOtp = otp.replace(/\D/g, ''); 
                    formikProps.setFieldValue('otp', numericOtp);
                  }}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  inputStyle={{ fontSize: "16px" }}
                  renderInput={(props) => <input {...props} className='otp-input' type="number" />}
                />
              </div>
              <p>Didn't receive OTP? <a onClick={() => handleResendOTP(formikProps)} href="#">Resend OTP</a></p>
              <Button
                className='my-3'
                variant="primary"
                type="submit"
                style={{ backgroundColor: '#7D3807', float: "right", borderColor: '#7D3807', color: "#fff" }}
              >
                Verify OTP
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      </Modal>

    </div>
  );
};

export default Navigation;
