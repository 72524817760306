import { Route, Routes } from 'react-router-dom';
import { AuthLayout } from './AuthLayout';
import PasswordResetWrapper from '../../pages/Forgot_password/PasswordResetWrapper'
import Home from '../../pages/landing/home';
import About from '../../pages/about-us/about';
import Contact from '../../pages/Contact/contact';
import Astrologers from '../../pages/astrologers/astrologers';
import AstrologerInner from '../../pages/Astrologer-Inner/astrloger-inner';
import Terms from '../../pages/more/terms';
import Wallet from '../../pages/more/wallet';
import Privacy from '../../pages/more/privacy';
import Deletion from '../../pages/more/account-deletion';
import Orders from '../../pages/more/orders';
import Customer from '../../pages/account/customer';
import Astrologersp from '../../pages/astrologers/astrologersp';
import AstrologerInnerp from '../../pages/Astrologer-Inner/astrologer-innerp';
import HoroscopeInner from '../../pages/more/Horoscopeinner';
import ChatMain from '../../pages/more/chatsmain';
import Astrologer from '../../pages/Astrologer-Inner/astrologer';
import Blogs from '../../pages/more/blogs';
import Kundaliform from '../../components/Kundliform';
import Kundali from '../../components/Kundali';
import Numeroform from '../../components/Numeroform';
import Numero from '../../components/Numero';
import Panchang from '../../components/Panchang';


const AuthPage = () => (
  <Routes>
    <Route path='/passwordreset' element={<PasswordResetWrapper />} />
    <Route path='/' element={<Home astrologerData={[]}/>} />
    <Route path='/about' element={<About />} />
    <Route path='/blogs' element={<Blogs />} />
    <Route path='/kundliform' element={<Kundaliform />} />
    <Route path='/kundli' element={<Kundali />} />
    <Route path='/numeroform' element={<Numeroform />} />
    <Route path='/panchang' element={<Panchang />} />
    <Route path='/numero' element={<Numero />} />
    <Route path='/blogs' element={<Blogs />} />
    <Route path='/contact' element={<Contact />} />
    <Route path='/astrologers' element={<Astrologersp astrologerData={[]} />} />
    <Route path='/horoscope-inner' element={<HoroscopeInner />} />
    <Route path='/astrologerprofile/:itemId' element={<AstrologerInnerp astrologerDataa={[]} />} />
    <Route path='/astrologerdetails/:astrologerId' element={<Astrologer />} />
    <Route path='/terms-and-conditions' element={<Terms />} />
    <Route path='/privacy-policy' element={<Privacy />} />
    <Route path='/account-deletion' element={<Deletion />} />
    <Route element={<AuthLayout />}>
      <Route path='/customer/account' element={<Customer />} />
      <Route path='/customer/astrologers' element={<Astrologers />} />
      <Route path='/customer/chatmain' element={<ChatMain selectedChat={{ astrologerPhone: "", status: "" }} />} />
      <Route path='/customer/transactions' element={<Wallet />} />
      <Route path='/customer/orders' element={<Orders />} />
      <Route path='/customer/astrologerprofile' element={<AstrologerInner />} />
    </Route>
  </Routes>
);

export { AuthPage };
