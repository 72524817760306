import axios from 'axios'

const Instance = axios.create({
  baseURL: 'https://dev.astropath.co.in/super_admin',
  // baseURL: 'http://13.51.6.161:8000/super_admin',
  // baseURL: 'http://localhost:5003',
  credentials: 'include',
  withCredentials: true,
})
export default Instance
