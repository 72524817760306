import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IoChatboxEllipses, IoCall } from "react-icons/io5";
import { Modal, Button, Form } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { Formik } from 'formik';
import OtpInput from 'react-otp-input';
import indiaFlag from '../../../_metronic/assets/card/flag.jpg'
import Navigation from '../../components/Navigation';
import Cookies from 'js-cookie';

interface Review {
  _id: string;
  customerName: string;
  rating: number;
  review: string;
}

interface AstrologerData {
  name: string;
  profile_photo: string;
  expertise: string[];
  gender: string;
  city: string;
  chat_price: number;
  voice_call_price: number;
  bio: string;
  reviews: Review[]; // Define the structure of reviews
}

const Astrologer: React.FC = () => {
  const { astrologerId } = useParams();
  const [astrologerData, setAstrologerData] = useState<AstrologerData | null>(null);
  const [show, setShow] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [orderId, setOrderId] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseVerifyModal = () => setShowVerifyModal(false);
  const handleShowVerifyModal = () => setShowVerifyModal(true);

    const sendOTP = async (phoneNumber: string) => {
      try {
        const formattedPhoneNumber = '+91' + phoneNumber;
        const response = await fetch('https://dev.astropath.co.in/backend/customer/otp', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ phone: formattedPhoneNumber }),
        });
      
        if (response.status === 200) {
          const data = await response.json();
          const orderIdFromResponse = data.payload.orderId; 
          setOrderId(orderIdFromResponse); 
          handleShowVerifyModal();
        } else {
          console.error('Error sending OTP. Status code:', response.status);
        }
      } catch (error) {
        console.error('Error sending OTP:', error);
      }
      
    };
  
  
    const verifyOTP = async (otp: string) => {
      try {
        const formattedPhoneNumber = '+91' + phoneNumber;
        const response = await fetch('https://dev.astropath.co.in/backend/customer/otpVerify', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ otp, phone: formattedPhoneNumber, orderId }),
        });
    
        if (response.status === 200) {
          const data = await response.json();
          const token = data.jwt_token;
          const userId = data.existingCustomer._id;
          handleCloseVerifyModal();
          Cookies.set('isLoggedIn', 'true', { expires: 7 });
          Cookies.set('token', token, { expires: 15 });
          Cookies.set('userId', userId, { expires: 15 });
          Cookies.set('astrologerId', astrologerId, { expires: 15 });
          setTimeout(() => {
            window.location.href = `/customer/astrologerdetails/${astrologerId}`;
          }, 400);
        } else {
          const errorData = await response.json();
          if (errorData.response.reason === "OTP Expired!") {
            toast.error("OTP has expired. Please request a new OTP.")
          } else {
            console.error('Error verifying OTP. Status code:', response.status);
          }
        }
      } catch (error) {
        toast.error("OTP has expired. Please request a new OTP.");
      }
    };
  
    const handleResendOTP = async (formikProps) => {
      try {
        const response = await fetch('https://dev.astropath.co.in/backend/customer/resendOtp', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ orderId: orderId }),
        });
        const data = await response.json();
        if (data.success) {
          for (let i = 1; i <= 6; i++) {
            formikProps.setFieldValue(`digit${i}`, '');
          }
        } else {
          console.error('Error resending OTP:', data.message);
        }
      } catch (error) {
        console.error('Error resending OTP:', error);
      }
    };

  useEffect(() => {
    fetchAstrologerProfile(astrologerId as any);
  }, [astrologerId]);

  const fetchAstrologerProfile = async (id: string) => {
    try {
      const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdXBlcl9hZG1pbl91c2VyX2lkIjpudWxsLCJhc3Ryb2xvZ2VyX3VzZXJfaWQiOm51bGwsImN1c3RvbWVyX3VzZXJfaWQiOiI2NWU2YmE4MDRkNzA4NjUxZjc4NTVmNjQiLCJpYXQiOjE3MTQ1ODYyNDB9.QI3_XqN6wu7cSxQoGWdHkwB8KmDhYmUbEC-x87xKFT0";
      const response = await fetch(`https://dev.astropath.co.in/customer/customer/get_astrologer/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (response.ok) {
        const responseData = await response.json();
        const data: AstrologerData = responseData.data[0];
        setAstrologerData(data);
      } else {
        console.error('Failed to fetch astrologer profile');
      }
    } catch (error) {
      console.error('Error fetching astrologer profile:', error);
    }
  };
  

  return (
    <>
      <div className="as_main_wrapper">
        <Navigation activePage="" />
        <div className="container yoyo">
          <div className="row cta-buttons">
            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <div className="as_blog_box">
                <div className="as_blog_img">
                  <div className='d-flex flex-row'>
                    <a>
                      <img
                        src={astrologerData ? astrologerData.profile_photo : ''}
                        alt="Image"
                        className="img-responsive blog-imag"
                      />
                    </a>
                    <div className="ost-cols py-4 px-12">
                      <h5 className='mt-3'>{astrologerData ? astrologerData.name : ''}</h5>
                      <p>{astrologerData ? astrologerData.expertise.join(', ') : ''}</p>
                      <p>{astrologerData ? astrologerData.gender : ''}, {astrologerData ? astrologerData.city : ''}</p>
                      <div className='d-flex align-items-center justify-content-center gap-5'>
                        <h5 className='mt-3'><IoChatboxEllipses style={{ fontSize: "24px", color: "orange", marginTop: "-2px" }} /> - ₹{astrologerData ? astrologerData.chat_price : ''}/min</h5>
                        <h5 className='mt-3'><IoCall style={{ fontSize: "24px", color: "orange", marginTop: "-2px" }} /> - ₹{astrologerData ? astrologerData.voice_call_price : ''}/min</h5>
                      </div>
                    </div>
                  </div>
                  <div className="cta-buttonss as-btn">
                      <button onClick={handleShow} title='Make a Call'> <img src="/images/call.png" className="btn-ico mx-2" alt="" /> Call </button>
                      <button onClick={handleShow} title='Start a Chat'> <img src="/images/chat.png" className="btn-ico mx-2" alt="" /> Chat </button>
                  </div>
                </div>
                <div className="as_blog_detail">
                  <h3 className="as_subheading">
                    <a href="javascript:;">About.</a>
                  </h3>
                  <p className="as_font14 as_margin0">{astrologerData ? astrologerData.bio : ''}</p>
                  <div className="as_padderTop30"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="testimonials">
        <div className="testimonial-heading">
          <h4>Reviews</h4>
        </div>
        <div className="testimonial-box-container">
          {astrologerData?.reviews.map((review: Review) => (
            <div className="testimonial-box" key={review._id}>
              <div className="box-top">
                <div className="profile">
                  <div className="profile-img">
                    <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" alt="Profile" />
                  </div>
                  <div className="name-user">
                    <strong>{review.customerName}</strong>
                    <span>@{review.customerName.toLowerCase().replace(/\s/g, '')}</span>
                  </div>
                </div>
                <div className="reviews">
                  {Array.from({ length: review.rating }, (_, index) => (
                    <i className="fas fa-star" key={index}></i>
                  ))}
                  {Array.from({ length: 5 - review.rating }, (_, index) => (
                    <i className="far fa-star" key={index}></i>
                  ))}
                </div>
              </div>
              <div className="client-comment">
                <p>{review.review}</p>
              </div>
            </div>
          ))}
        </div>
      </section>


      <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton style={{ backgroundColor: '#7D3807', color: '#fff' }}>
              <Modal.Title>Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{ phoneNumber: '' }}
                onSubmit={(values, actions) => {
                  setPhoneNumber(values.phoneNumber);
                  sendOTP(values.phoneNumber);
                  handleClose();
                  actions.setSubmitting(false);
                  handleShowVerifyModal();
                }}
              >
                {(formikProps) => (
                  <Form onSubmit={formikProps.handleSubmit}>
                    <Form.Group controlId="formBasicPhoneNumber">
                      <Form.Label>Phone Number</Form.Label>
                      <div style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', width: '40px', height: 'auto', display:"flex", alignItems:"center", justifyContent:"start"}}>
                          <img src={indiaFlag} alt="India Flag"  />
                          <p className='mx-1' style={{margin:"auto 0"}}>+91</p>
                        </div>
                        
                        <Form.Control
                          type="tel"
                          name="phoneNumber"
                          maxLength={10}
                          value={formikProps.values.phoneNumber}
                          onChange={(event) => {
                            const phoneNumber = event.target.value.replace(/\D/g, '');
                            formikProps.setFieldValue('phoneNumber', phoneNumber.slice(0, 10));
                          }}
                          onBlur={formikProps.handleBlur}
                          style={{ border: '1px solid #7D3807', borderRadius: '5px', paddingLeft: '85px', marginBottom: "10px", fontSize:"16px" }} 
                        />

                      </div>
                      <Form.Text className="text-muted fs-6">
                        We'll never share your phone number with anyone else.
                      </Form.Text>
                    </Form.Group>
                    <Button
                      className='my-3'
                      variant="primary"
                      type="submit"
                      style={{ backgroundColor: '#7D3807', float: "right", borderColor: '#7D3807', color: "#fff" }}
                    >
                      Send OTP
                    </Button>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
        </Modal>
        <Modal show={showVerifyModal} onHide={handleCloseVerifyModal} centered>
        <Modal.Header closeButton style={{ backgroundColor: '#7D3807', color: '#fff' }}>
            <Modal.Title>Verify OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Formik
          initialValues={{ otp: '' }}
          onSubmit={(values, actions) => {
            const otp = values.otp;
            verifyOTP(otp);
            actions.setSubmitting(false);
          }}
        >
          {(formikProps) => (
            <Form onSubmit={formikProps.handleSubmit}>
              <h6 style={{ color: "#000", margin: "10px 0px 20px 0px" }}>Please enter OTP sent to your mobile number.</h6>
              <div style={{ display: 'flex', justifyContent: 'center', gap: "5px", margin: "10px 0px" }}>
                <OtpInput
                  value={formikProps.values.otp}
                  onChange={(otp) => {
                    const numericOtp = otp.replace(/\D/g, ''); 
                    formikProps.setFieldValue('otp', numericOtp);
                  }}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  inputStyle={{ fontSize: "16px" }}
                  renderInput={(props) => <input {...props} className='otp-input' type="number" />}
                />
              </div>
              <p>Didn't receive OTP? <a onClick={() => handleResendOTP(formikProps)} href="#">Resend OTP</a></p>
              <Button
                className='my-3'
                variant="primary"
                type="submit"
                style={{ backgroundColor: '#7D3807', float: "right", borderColor: '#7D3807', color: "#fff" }}
              >
                Verify OTP
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      </Modal>
    </>
  );
}

export default Astrologer;
