import React, { useState, ReactElement } from 'react';

const Terms = () => {
  
  return (
    <> 
                <main className="col-12 align-items-center d-flex flex-column gap-3 justify-item-center">
            <section className="as_header_wrapper">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6">
                      <div className="as_logo">
                        <a href="/">
                          <img className="logos" src="/images/logotext.webp" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-8 col-xs-6">
                      <div className="as_right_info">
                        <div className="as_menu_wrapper">
                          <span className="as_toggle">
                            <img src="/images/svg/menu.svg" alt="" />
                          </span>
                          <div className="as_menu">
                            <ul>
                              <li><a href="/">home</a></li>
                              <li><a href="/about">about us</a></li>
                              <li><a href="/astrologers">Astrologers</a></li>
                              <li><a href="/contact">Contact</a></li>
                        </ul>
                      </div>
                    </div>                
                  </div>
                </div>
              </div>
            </div>
          </section>

            <section className="col-8 pivc">
                <h2 style={{color:"#000"}} className="pivc">Terms and Conditions for Astropath</h2>
                <hr />
                <h4><strong>1 - General Description and Acceptance of Terms</strong></h4>
                <p>Thank You for your interest in Astroapth and welcome to our Website. These Terms describe and regulate the scope of Services provided to a User by PKM TECH PRIVATE LIMITED through website and mobile application.</p>
                <p>This Website is designed to provide the Users with access to astrological consultation services through audio, chat, or written correspondence in the form of reports (“ Service(s)”) through its Website and Mobile Application (“Website”). The scope of these Services is described in detail in these Terms. This Website provides a variety of services some of which shall be accessible by the User on payment of a designated fee (“Paid Services”)while the other Services shall be available free of charge (“Free Services”).</p>
                <p>These Terms is an electronic agreement between the User (" User", "you", "your") and Astropath. You are advised to read these terms carefully before accessing any information, Service, etc. on the Website.</p>
                <p>By accessing the Website, you unconditionally give consent to these Terms and agree to be legally bound by them. If these Terms or any part thereof seems unacceptable to you, We recommend that you refrain from using the Website,</p>
                <p>We reserve an absolute right to stop providing you with Services through our Website or to change, alter, modify or refine them without providing you with any prior notice. Your inability to access our Website at any time shall not impose any legal liability on us. Additionally, We reserve the right to limit your access to all the entire or any portion of the Website.</p>
                <h4><strong>2. Scope Of Services</strong></h4>
                <p>The Services provided by us include but are not limited to astrological content, reports, tarot card readings, face readings, palmistry, life coaching and counselling in the Services offered, numerology, predictions, live telephone consultations, Chat and products sold through our shopping platform including but not limited to gem stones, rudraksh etc. Astropath does not warrant the reliability of any consultation provided to a User or a Registered Member.</p>
                <p>The Services Provided by us are classified into two categories i.e., Free Services and Paid Services. The nature of each Service and the amount payable by a User for access to such Service, if any, shall be visible to the User on the Website. Our Free Services are available to everyone who visits our Website. However, to use our Paid Services, a User shall be required to complete the registration process on the Website (“Registered Member”). A Registered Member accessing any Paid Service shall be required to provide us with updated and accurate information as prompted on the Website and to inform us of any change in such information to guarantee better output.</p>
                <h3>3. Eligibility</h3>
                <p>Astropath’s Services are available only to individuals who are competent to contract as per the Indian Contract Act 1872. The following class of person shall be ineligible to access Astropath’s Services;</p>
                <ul>
                    <li>A minor i.e., a person under the age of eighteen years, unless acting through a legal guardian or unless otherwise allowed by the law applicable to such minor.</li>
                    <li>Individuals who have been temporarily or indefinitely suspended as Users by Astropath.</li>
                    <li>A person prohibited by the law applicable to him to access such Services.</li>
                </ul>
                <h3>4. Access To User Information</h3>
                <p>Every User unconditionally consents to us perusing the information provided by such User or Registered Member in order to ensure, maintain and provide our Services to the best of our ability and for grievance redressed if need be. User agree to receive the communication from Astropath for newsletters, information updates, notification and promotional communication through SMS, email WhatsApp and other channels.</p>
                <h3>5. Registration As A Member</h3>
                <p>To use our Paid Services, you shall be required to create an account with us to become a Registered Member. The registration process may require you to provide us with current and updated personal information including but not limited to your phone number, e-mail, date of birth, and other personal information as prompted on the Website during the registration process.</p>
                <p>The registration process shall require you to share with us a One Time Password (“OTP”) that the User shall receive on the phone number provided by him/her during the registration process.</p>
                <p>An account can be created by other login methods through the User’s Gmail login information. No User can have more than one valid account registered with a single phone number.</p>
                <h3>6. Astropath’s Right To Suspend A User Account</h3>
                <p>Astropath does not independently verify the veracity of the information provided by a User or Registered Member and shall assume no liability arising out of any inaccuracy in the data provided to User, or Registered Member on the basis of such information. Further, Astropath reserves the right to temporarily or permanently suspend the account of a Registered Member or restrict his right of usage of certain Services or their access the Website, without giving any prior notice to such Registered Member, in the event that the information provided by such Registered Member is found to be inaccurate or in the event the Registered Member is found to have indulged in making defamatory statements against or regarding Astropath, its employees, officers, agents, affiliates or regarding the Services provided through the Website.</p>
                <h3>7. Security Of Registered Member’s Account</h3>
                <p>The account of a Registered Member is protected by means of a username and password ("Login Information") known only to the Registered Member at the time of registration of the account and such Login Information should not be shared with anyone else. Each Registered Member is responsible for maintaining the confidentiality of their Login Information and every Registered Member agrees to immediately notify us of any unauthorized use of their Login Information or any other breach of security.</p>
                <p>The Registered Member is solely responsible for the security and confidentiality of his/her payment accounts linked to his/her registered account on the Website. Astropath shall bear no responsibility in case of any unauthorized use of such linked payment account by a third party. Further any such unauthorized use, which a Registered Member is aware of or suspects, should be reported to us without any unreasonable delay.</p>
                <p>It shall be the sole responsibility of a Registered Member to log out of their account after the completion of a session and any loss or damage of whatsoever nature caused due to failure to follow the said procedure shall be borne by such Registered Member alone and Astropath shall not, in any way be held liable for any consequential loss or damage.</p>
                <p>No User shall copy or modify, the technology and software associated with the Website. Further, the Users accept not to rent, loan, sell, assign, distribute, reverse engineer, grant a security interest in, or otherwise transfer any right to any one of the associated technologies and software owned or used by the Website.</p>
                <p>Users agree not to disrupt, overburden, aid or assist in the disruption or overburdening of (a) any computer or server used to offer or support the Website; or (b) the enjoyment of Services by any other User. Any unauthorized access to the Registered Member’s Account, servers, or networks associated with the Website’s Services by any means except as provided by Astropath shall be deemed to constitute a breach of these Terms.</p>
                <p>No User shall indulge in activities like circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software associated with the Services.</p>
                <p>The User accepts, agrees, and acknowledges that he/she will neither support nor conduct any sort of attack including (but not limited to) distribution of a virus, denial of service, or other attempts to interrupt the Services provided by the Website.</p>

                <h3>8. Communication With Astrologers</h3>
                <p>Astropath’s Services also include consultation with various astrological experts (“Astrologers”) who have enrolled with Astropath to provide consultation to our Users via electronic mediums including but not limited to phone calls, Chat, texts, etc. Your consent to these Terms also constitutes your consent to us arranging a consultation call with such Astrologers should you choose to avail of a Service that requires such telephonic consultation.</p>
                <p>The Astrologers enrolled with us are prohibited to share their personal details like contact number, email, social media username, address, etc with any User and are also prohibited to indulge in or suggest activities such as black magic, vashikaran, or suggest any pooja using these tantras.</p>
                <p>The Users hereby agree to promptly inform Astropath of any violation or attempt at a violation of such Terms by an Astrologer. Failure on the part of a User or a Registered Member to furnish such information may lead to a temporary suspension of the Registered Member’s account or restriction on such User to access the Website as the case may be.</p>

                <h3>9. Privacy Policy</h3>
                <p>Astropath’s privacy policy clearly defines how it uses, stocks, releases, and processes data and information from Registered Members, in whole or in part, through various channels. The privacy policy is all about our privacy pledge. It is simply about utilizing your valuable and confidential data, including Google login By accessing this Website, you accept, agree, and acknowledge that all information collected by us from you including but not limited to registration and all payment information, is subject to Astropath’s Privacy Policy which is available at  Website.</p>

                <h3>10. Breach Of Terms And Termination Of Account</h3>
                <p>In the event of a breach of any part of these Terms by a User or a Registered Member, Astropath reserves the right to immediately block the Registered Member’s account or absolutely restrict the User’s access to the Website. This right to terminate and restrict may be exercised by Astropath in the following circumstances.</p>
                <ul>
                <li>If the Website is not able to verify the veracity of the information provided by a Registered Member or a User or if such information is discovered to be false or misleading.</li>
                <li>If in our opinion, the actions of a Registered Member or a User have the ability to impose legal liability on the Website or any affiliated third party;</li>
                <li>If the actions of a Registered Member or a User cause interference with our Services or are violative of our privacy policy.</li>
                </ul>
                <p>A Registered Member may deactivate his/her account at any time by writing to us at [insert email address].</p>
                <p>Upon termination, Astropath reserves the right to retain your information for a period of [Insert Timeline] for recording purposes. The information so collected shall automatically be deleted after completion of such period.</p>

                <h3>11. User’s Responsibility</h3>
                <p>it is our duty to inform you that you shall not host, display, upload, modify, publish, transmit, store, update or share any information that,</p>
                <ul>
                <li>belongs to another person and to which the User does not have any right;</li>
                <li>is defamatory, obscene, pornographic, bodily privacy, insulting or harassing based on gender, libellous, racially or ethnically objectionable, relating or encouraging money laundering or gambling, or otherwise inconsistent with or contrary to the applicable laws.</li>
                <li>is harmful to children;</li>
                <li>infringes any patent, trademark, copyright, or other proprietary rights;</li>
                <li>violates any law for the time being in force;</li>
                <li>deceives or misleads the addressee about the origin of the message or knowingly and intentionally communicates any information which is patently false or misleading in nature but may reasonably be perceived as a fact;</li>
                <li>impersonates another person;</li>
                <li>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting other nation;</li>
                <li>contains a software virus or any other computer code, file, or program designed to interrupt, destroy or limit the functionality of any computer resource;</li>
                <li>is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person;</li>
                </ul>
                <p>Further, you agree that you shall not-</p>
                <ul>
                <li>collect screen names and email addresses of Users or Registered Members on the Website for purposes of advertisement, solicitation, or spam.</li>
                <li>send unsolicited emails, spam, promotions, or advertisements for products or Services.</li>
                <li>engage in any activity that interferes with or disrupts access to the Website</li>
                <li>violate any applicable laws or regulations for the time being in force within or outside India.</li>
                <li>resell or make any commercial use of the Services without the express written consent from the Website.</li>
                <li>violate these Terms including but not limited to any applicable additional terms of the Website contained herein or elsewhere.</li>
                </ul>

                <h3>12. Representations And Warranties</h3>
                <p>All Services available to a User through this Website are provided on an "as is" and "as available" basis without any representation or warranties, express or implied unless otherwise specified in writing.</p>
                <p>Except as provided below, this Website does not warrant that:</p>
                <ul>
                <li>this Website will be constantly available and without any interruptions or errors;</li>
                <li>the information available on this Website is complete, true, accurate, and non-misleading;</li>
                <li>this Website shall be accountable to you in any manner for your use of this Website or for the contents available therein.</li>
                <li>its servers, information, content, materials (including software), Services, or other content made available to you through the Website do not contain any viruses or other harmful components;</li>
                <li>any information available on the Website is meant to constitute advice of any kind;</li>
                <li>the Website will indemnify you for misuse, loss, modification, or unavailability of any User information.</li>
                </ul>
                <p>Every User and Registered Member hereby warrants that they shall disclose, their current emotional mental, or physical state, fully and truthfully to the Astrologers enrolled with us, from whom consultation is being sought.</p>
                <h3>13. Limitation Of Astropath’s Liability</h3>
                <p>You understand and agree that to the maximum extent permitted under applicable law, Astropath does not provide any warranty for the Services provided and that consultation provided by each Astrologer enrolled with the Website may differ as the same is dependent on individual knowledge, skills, experience and type of methods employed.</p>
                <p>The Website has several Astrologers that have enrolled with it as service providers, and who may while interacting with a Registered Member or a User make certain astrological recommendations to the User. It is hereby clarified by us that though all recommendations made by the Astrologers are in good faith, any discrepancy in such recommendation that may be discovered by you shall not make us or any of our affiliates legally liable for the same.</p>
                <p>The Website shall bear no responsibility for any damage or loss that may be incurred by anyone due to the unauthorised use of your account with or without your knowledge. Although Astropath has made its best efforts to provide accurate information regarding its Services still we do warrant the correctness or completeness of such information.</p>
                <p>Astropath disclaims that this is not a helpline platform for suicide prevention and if you are someone who is having suicidal thoughts, we recommend that you immediately approach the relevant police authority, or medical professional or contact the national or local suicide prevention helpline.</p>
                <p>Further, Users and Registered Members are advised not to enter into independent contracts with the Astrologers available on the Website and if such contract is entered into, Astropath shall not be responsible for any consequence or liability that may arise from such a contract.</p>
                <p>You agree and understand that any material downloaded by you from the Website shall be entirely at your own risk and that you will bear the sole responsibility for any loss or damage caused to your computer system or Smartphone that results as a consequence of such downloaded material. Further, any coupon denied due to a grammatical or typographical error shall impose no liability on the Website.</p>
                <p>The Astrologers and other service providers enrolled with us are merely members of the Website and not Astropath’s employees. While the website makes the best endeavour to verify the qualifications and credentials of the Astrologers and service providers, our review is limited to the information provided to us by such Astrologers and service providers and we do not warrant the accuracy, veracity, or correctness of such information. Further Astropath asserts that it is merely a platform to connect Astrologers and service providers with the Users and enrolment of any service provider or Astrologer with the Website does not amount to an endorsement.</p>
                <p>Astropath is not a medical institution and does not guarantee the correctness or accuracy of any medical advice provided by an Astrologer or a service provider. Each User and Registered Member is advised to seek independent medical advice from a licensed medical professional in case of any doubts. Further, we shall bear no responsibility for any monetary loss or physical issues incurred by a User or Registered Member acting pursuant to medical advice rendered by such Astrologer.</p>
                <p>Not with standing anything to the contrary contained in these Terms, Astropath’s liability for any action or consequence whatsoever, regardless of its form of the action, will at all times be limited to the money paid, if any, by a Registered Member to Astropath, for the Services availed by it.</p>

                <h3>14. Banking And Payments Information</h3>
                <p>All payments towards usage of our Paid Services can only be made through a Registered Member’s wallet linked to his account (“Astropath Wallet”). To deposit money in the Astropath Wallet, a Registered Member will have to provide his/her banking details using which he/she shall be able to deposit the required amount from his bank account into his/her Astropath Wallet.</p>
                <p>With respect to such banking information, each Registered Member opting for a Paid Service warrants that-</p>
                <ul>
                <li>The debit/credit card, net banking, and UPI details provided by such Registered Member are correct and accurate, and he/she is legally authorised to use such banking information to deposit money in his/her Astropath Wallet.</li>
                </ul>

                <h3>15. Refund & Cancellation Policy</h3>
                <h4>Return & Cancellation Policy for Services</h4>
                <p>A Request for a refund for a Paid Service shall be initiated if:</p>
                <ul>
                <li>A Registered Member faces a network issue due to which the consultation chat/call gets affected in between or if there is a weak signal, background noise, inaudible consultant, etc. during the video/normal call sessions; or</li>
                <li>The Astrologer is unable to communicate clearly in the language mentioned in their profile; or</li>
                <li>The Astrologer takes long enough to respond to the Registered Member.</li>
                <li>If a Registered Member is dissatisfied with the quality of advice or consultation provided by an Astrologer. In such an event, the initiation of refund shall be subject to a review by and satisfaction of Astropath, regarding the quality of consultation or advice provided to the dissatisfied member and Astropath’s decision on this issue shall be final and not subject to any challenge. An aggrieved Registered Member may contact us regarding such an issue by writing to us at [insert email address].</li>
                </ul>
                <p>No amount paid by a Registered Member towards a Paid Service shall be refunded-</p>
                <ul>
                <li>If the request for refund is initiated by the Registered Member two hours after the time of payment. However, if payment has been made twice for the same Service, then the amount paid subsequently shall be refunded automatically.</li>
                <li>If the information provided by them on the Website is discovered to be incorrect.</li>
                <li>If customers have gotten a consultation report or have availed of the Service through other electronic means.</li>
                <li>If a wrong phone number is provided while availing of telephonic Services.</li>
                <li>If the consultation with an Astrologer has begun.</li>
                </ul>
                <p>If an order for a Service has been placed by a User in haste or a careless manner, the liability for any consequence or damage arising from such order shall be solely borne by such User</p>
                <p>Astropath shall endeavour to process all refunds within 7 (seven) business days.</p>
                <p>All refunds generated through the Website shall be credited to the User’s Astropath Wallet on the Website.</p>
                <h4>Comprehensive Return & Cancellation Policy for Products</h4>
                <p>Each User agrees that the pictures displayed for the products on the Website are for reference purposes only and Astropath shall Endeavour to deliver the product ordered in an as-is condition as displayed on the Website. The User is advised to exercise discretion in such cases.</p>
                <p>No amount paid by a User towards a product shall be refunded if-</p>
                <ul>
                <li>If the product for which a refund is sought is a printable product such as Astropath Horoscope, Janam Patri, etc.</li>
                <li>If a refund request is initiated for a product by a User after two days or receiving delivery of a product that is eligible for return. However, if payment has been made twice for the same Service, then the amount paid subsequently shall be refunded automatically.</li>
                </ul>
                <p>Products that are received by a User in a broken or damaged state shall be eligible for a refund if the same is approved by the Website. The Users receiving the product are advised to make a video of unpacking the product to submit as proof in case a request for a return or refund is initiated.</p>
                <p>Astropath shall endeavour to process all refunds within (7) seven business days.</p>
                <p>All refunds generated through the Website shall be credited to the User’s Astropath Wallet on the Website.</p>
                <h3>16. Modification Of These Terms</h3>
                <p>Astropath reserves the right to modify or update these Terms at any time. No notice of such update or modification shall be sent to a User or a Registered Member and such User or Registered Member is required to visit the Website periodically to keep updated with these Terms.</p>

                <h3>17. Links To Third -Party Websites</h3>
                <p>Our Website may contain links to other websites or platforms which are governed by their terms of use over which we have no control and any user accessing such website or platform using such links, shall, once they leave our Website, be governed by the terms and conditions of the concerned website or platform and Users are advised to review such terms and conditions before transacting on such other websites and platforms. We take no responsibility for the terms and conditions or the contents of such a website or platform. Further reference on this Website to any third-party content or links to any other website or platform should not be understood as any sort of endorsement by us of such content, website, or platform.</p>
                <p>Astropath is in no way associated with any correspondence, transaction, or other related activity between a User and a third party including payment and verification service providers, conducted on the Website and bears no responsibility whatsoever for the same.</p>
                <p>Acceptance of these Terms by you represents your acknowledgment of exclusion of our responsibility and or liability for any loss or damage, of whatever nature, that You may suffer due to any transaction correspondence or related activity transacted by you on such third-party websites and platforms.</p>
                <p>This Website contains information that is created by us as well as content provided by third parties. We do not guarantee the accuracy, integrity, or quality of the content provided by third parties and such content may not be relied upon by the Users in utilizing our Services.</p>

                <h3>18. Intellectual Property</h3>
                <p>Access to our Website shall only grant you a limited license to use our information.</p>
                <p>You are authorized to save the data from the Website to any device for your personal use as long as you don't remove or alter any copyright symbols, trademarks, or other proprietary material. You are not permitted to use our information for any other reason than what is stated above. You acknowledge that any unauthorised use of the confidential information on the Website is a violation of our intellectual property rights, for which you agree to hold us harmless.</p>
                <p>Except for any third-party elements that are expressly designated as such, We declare that We are the exclusive owner of all the contents displayed on the Website, including all graphics, logos, sound recordings, and software. The law that is in effect in India shall apply to any infringement of our intellectual property rights. The license to access and use our Services does not include the right to copy or reproduce our information on any other platform or medium, without our prior written consent.</p>
                <p>Unless otherwise stated, any word, logo, or object bearing the markings denoting the grant of Intellectual Property Rights as a trademark must be regarded as a registered trademark that We either own or have permission to use. A User’s right to access our Website shall not be deemed to grant a license for use such User to use our Intellectual Property Rights.</p>

                <h3>19. Miscellaneous</h3>
                <p>Entire Agreement and Assignment -These Terms constitute the entire agreement between you and Astropath relating to the subject matter and cancel and supersede any prior versions of the Terms. You may not assign or otherwise transfer the Terms or any right granted hereunder. You also may be subject to additional terms and conditions that may apply when you use this Website or third-party products or services.</p>
                <p>No Partnership -No partnership or joint venture between Astropath and you shall be implied by these Terms. Nothing in these Terms shall be interpreted to make you or Astropath as the other party's agent.</p>
                <p>Waiver -Any failure on our part to exercise any provision or right under these Terms, shall not constitute a waiver by us of that provision or right.</p>
                <p>Injunctive Relief-You acknowledge that any significant violation of the Terms will cause irreparable harm to the Astropath for which monetary compensation would be insufficient and that as a result, in addition to any other legal rights or remedies, we shall be entitled to equitable relief, including both a temporary injunction and a permanent injunction, in the event that such a violation takes place. In the event that Astropath files for an injunction, you accept and agree to waive any obligation for the posting of a bond or other security.</p>
                <p>Time shall be of essence -Unless expressly stated by us on the Website or through a written agreement, time shall be of the essence concerning all the matters arising out of or in connection with these Terms.</p>
                <p>THE USER ACKNOWLEDGES THAT IT HAS READ THESE TERMS OF USE, UNDERSTANDS THEM, AND AGREES TO BE BOUND BY ITS TERMS. THE USER FURTHER ACKNOWLEDGES THAT THIS AGREEMENT DOES NOT HAVE TO BE SIGNED IN ORDER TO BE BINDING. THE USER WILL HAVE INDICATED ITS ASSENT TO THE TERMS OF THE AGREEMENT BY CLICKING ON THE "ACCEPTED AND AGREED TO" (OR SIMILAR BUTTON) THAT IS PRESENTED TO THE USER AT THE TIME OF ITS OPENING OF AN ONLINE ACCOUNT WITH THE COMPANY.</p>

                <br />
                <h3>Contact Us</h3>
                <br />
                <p>If you have any questions about this Privacy Policy, You can contact us: info@astropath.com</p>
            </section>
            
        </main>
    </>
  )
}

export default Terms;