import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Cookies from 'js-cookie';
import { ChangePassword } from '../modules/profile/components/ChangePassword'
import PasswordPage from '../../app/pages/Forgot_password/PasswordResetWrapper'
import Astrologers from '../pages/astrologers/astrologers'
import Wallet from '../pages/more/wallet'
import Orders from '../pages/more/orders'
import AstrologerInner from '../pages/Astrologer-Inner/astrloger-inner'
import ChatMain from '../pages/more/chatsmain'
import Chatss from '../pages/more/chatss'
import Astrologerprofile from '../pages/Astrologer-Inner/astrologerprofile'
import Supportform from '../components/Support'
import Kundaliformi from '../components/Kundliformi'
import Kundli from '../components/Kundli'
import Numeroformm from '../components/Numeroformm'
import Numeroo from '../components/Numeroo'
import Panchangi from '../components/Panchangi'
import Formivr from '../components/formivr'
import Formchat from '../components/formchat'


const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const token = Cookies.get('token');
  return (
    <Routes>
      <Route element={<MasterLayout />}>
          <Route path='builder' element={<BuilderPageWrapper />} />
          <Route path='menu-test' element={<MenuTestPage />} />
          <Route path='superadmin/changepassword' element={<ChangePassword/>}/>
          <Route path='superadmin/forgotPassword' element={<PasswordPage />}/>
          {token && token !== 'null' ? ( 
            <>
              <Route path='/' element={<Navigate to='/customer/astrologers' />} />
              <Route path='/customer/astrologers' element={<Astrologers />} />
              <Route path='/customer/support' element={<Supportform />} />
              <Route path='/customer/chats' element={<Chatss />} />
              <Route path='/customer/kundliform' element={<Kundaliformi />} />
              <Route path='/customer/numeroform' element={<Numeroformm />} />
              <Route path='/customer/formivr' element={<Formivr />} />
              <Route path='/customer/formchat' element={<Formchat />} />
              <Route path='/customer/panchang' element={<Panchangi />} />
              <Route path='/customer/numero' element={<Numeroo />} />
              <Route path='/customer/kundli' element={<Kundli />} />
              <Route path='/customer/chatmain' element={<ChatMain selectedChat={{ astrologerPhone: "", status: "" }} />} />
              <Route path='/customer/astrologerprofile' element={<AstrologerInner />} />
              <Route path='/customer/transactions' element={<Wallet />} />
              <Route path='/customer/orders' element={<Orders />} />
              <Route path='/customer/astrologerdetails/:itemId' element={<Astrologerprofile />} />
            </>
          ) : (
            null 
          )}
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }