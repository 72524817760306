import React, { useState, useEffect, CSSProperties } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { IoVideocam, IoCall, IoChatboxEllipses } from "react-icons/io5";
import { CloseOutlined, Height } from '@mui/icons-material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Cookies from 'js-cookie';
import { DatePicker, TimePicker } from 'antd';
import { FormikHelpers } from 'formik';
import dayjs from 'dayjs';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import toast, { Toaster } from 'react-hot-toast';


interface Review {
  _id: string;
  customerName: string;
  rating: number;
  review: string;
}

interface AstrologerData {
  name: string;
  profile_photo: string;
  _id: string;
  is_active: boolean;
  expertise: string[];
  gender: string;
  city: string;
  chat_price: number;
  voice_call_price: number;
  bio: string;
  reviews: Review[];
}

const overlayStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.3s, visibility 0.3s',
  };
  
  const activeOverlayStyle: CSSProperties = {
    opacity: 1,
    visibility: 'visible',
  };
  
  const contentStyle: CSSProperties = {
    backgroundColor: '#fff',
    padding: '10px 5px',
    borderRadius: '5px',
    width: '85%',
    height: '70%',
    display:"flex",
    flexWrap:"wrap",
    overflowY: 'auto',
  };
  
  const contentStyle1: CSSProperties = {
    backgroundColor: '#fff',
    padding: '40px 40px',
    borderRadius: '5px',
    width: '70%',
    justifyContent:"center",
    textAlign:"center",
    height: '70%',
    overflowY: 'auto',
  };

const Astrologerprofile: React.FC = () => {
  const { astrologerId } = useParams();
  const [astrologerData, setAstrologerData] = useState<AstrologerData | null>(null);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [selectedServiceCost, setSelectedServiceCost] = useState(0);
  const [selectedService, setSelectedService] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<{ lat: string; lon: string; } | null>(null);
  const [selectedTimezone, setSelectedTimezone] = useState('+5.30');
  const [timer, setTimer] = useState(5 * 60);

  useEffect(() => {
    const astrologerIdFromCookies = Cookies.get('astrologerId');
    if (astrologerIdFromCookies) {
      fetchAstrologerProfile(astrologerIdFromCookies);
    } else {
      console.error('AstrologerId not found in cookies');
    }
  }, []);
  

  const fetchAstrologerProfile = async (id: string) => {
    try {
      const token = Cookies.get('token');
      const response = await fetch(`https://dev.astropath.co.in/customer/customer/get_astrologer/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (response.ok) {
        const responseData = await response.json();
        const data: AstrologerData = responseData.data[0];
        setAstrologerData(data);
      } else {
        console.error('Failed to fetch astrologer profile');
      }
    } catch (error) {
      console.error('Error fetching astrologer profile:', error);
    }
  };
  
  const handleSubmit = async (values: {
    full_name: string;
    time_of_birth: Date | null;
    date_of_birth: Date | null;
    place_of_birth: string;
    gender: string;
  }, formikHelpers: FormikHelpers<{
    full_name: string;
    time_of_birth: Date | null;
    date_of_birth: Date | null;
    place_of_birth: string;
    gender: string;
  }>) => {
    try {
      const missingFields: string[] = [];
      if (!values.full_name) {
        missingFields.push('Full Name');
      }
      if (!values.time_of_birth) {
        missingFields.push('Time of Birth');
      }
      if (!values.date_of_birth) {
        missingFields.push('Date of Birth');
      }
      if (!values.gender) {
        missingFields.push('Gender');
      }
      
      if (missingFields.length > 0) {
        const missingFieldsMessage = missingFields.join(', ');
        toast.error(`Please fill in the following fields: ${missingFieldsMessage}`);
        return;
      }
      const token = Cookies.get('token');
      const timeOfBirth = values.time_of_birth;
      const hour = timeOfBirth ? timeOfBirth.getHours() : 0;
      const min = timeOfBirth ? timeOfBirth.getMinutes() : 0;
      const dateOfBirth = values.date_of_birth;
      const day = dateOfBirth ? dateOfBirth.getDate() : 0;
      const month = dateOfBirth ? dateOfBirth.getMonth() + 1 : 0;
      const year = dateOfBirth ? dateOfBirth.getFullYear() : 0;
      
      const kundaliData = {
        name: values.full_name,
        day: day,
        hour: hour,
        min: min,
        month: month,
        year: year,
        lat: selectedLocation?.lat,
        lon: selectedLocation?.lon, 
        timeZone: selectedTimezone,       
      };
      const { lat, lon } = kundaliData;
      if (!lat || !lon) {
        missingFields.push('Place of Birth');
      }
      const response = await fetch('https://dev.astropath.co.in/customer/customer/request_ivr_token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          astrologerId: astrologerData?._id,
          service_cost: selectedServiceCost,
          services: selectedService,
          kundali_data: kundaliData,
        }),
      });
    
      const data = await response.json();
      if (data.success === 1) {
        setVisible(false);
        toast.success('Astrologer Selected Successfully !!\nYou will receive a call in 5 mins', {
          style: {
            background: '#fff',
            color: '#000',
          },
          icon: <img src="https://png.pngtree.com/png-vector/20230105/ourmid/pngtree-d-green-check-mark-icon-in-round-isolated-transparent-background-tick-png-image_6552327.png" alt="Not Found" style={{ width: '70px', height: '70px' }} />,
        });
        setShowSuccessModal(true)
      } else {
        toast.error(data.msg);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again later.');
      console.error('Error:', error);
    }    
  };

  const navigateToChats = () => {
    navigate('/customer/chats');
  };

  const handleSubmit1 = async (values: {
    full_name: string;
    time_of_birth: Date | null;
    date_of_birth: Date | null;
    place_of_birth: string;
    gender: string;
  }, formikHelpers: FormikHelpers<{
    full_name: string;
    time_of_birth: Date | null;
    date_of_birth: Date | null;
    place_of_birth: string;
    gender: string;
  }>) => {
    try {
      const missingFields: string[] = [];
      if (!values.full_name) {
        missingFields.push('Full Name');
      }
      if (!values.time_of_birth) {
        missingFields.push('Time of Birth');
      }
      if (!values.date_of_birth) {
        missingFields.push('Date of Birth');
      }
      if (!values.gender) {
        missingFields.push('Gender');
      }
      
      if (missingFields.length > 0) {
        const missingFieldsMessage = missingFields.join(', ');
        toast.error(`Please fill in the following fields: ${missingFieldsMessage}`);
        return;
      }
      const token = Cookies.get('token');
      const timeOfBirth = values.time_of_birth;
      const hour = timeOfBirth ? timeOfBirth.getHours() : 0;
      const min = timeOfBirth ? timeOfBirth.getMinutes() : 0;
      const dateOfBirth = values.date_of_birth;
      const day = dateOfBirth ? dateOfBirth.getDate() : 0;
      const month = dateOfBirth ? dateOfBirth.getMonth() + 1 : 0;
      const year = dateOfBirth ? dateOfBirth.getFullYear() : 0;
      
      const kundaliData = {
        name: values.full_name,
        day: day,
        hour: hour,
        min: min,
        month: month,
        year: year,
        lat: selectedLocation?.lat,
        lon: selectedLocation?.lon, 
        timeZone: selectedTimezone,       
      };
      const { lat, lon } = kundaliData;
      if (!lat || !lon) {
        missingFields.push('Place of Birth');
      }
      const response = await fetch('https://dev.astropath.co.in/customer/customer/request-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          astrologerId: astrologerData?._id,
          service_cost: selectedServiceCost,
          services: selectedService,
          kundali_data: kundaliData,
        }),
      });
    
      const data = await response.json();
      if (data.success === 1) {
        setVisible(false);
        toast.success('Astrologer Selected Successfully !!\nYou will be redirected to chat page', {
          style: {
            background: '#fff',
            color: '#000',
          },
          icon: <img src="https://png.pngtree.com/png-vector/20230105/ourmid/pngtree-d-green-check-mark-icon-in-round-isolated-transparent-background-tick-png-image_6552327.png" alt="Not Found" style={{ width: '70px', height: '70px' }} />,
        });
        setTimeout(() => {
          window.location.href = '/customer/astrologers';
        }, 4000);
        navigateToChats();
      } else {
        toast.error(data.msg);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again later.');
      console.error('Error:', error);
    }    
  };
  
  const handleLocationSelect = async (selectedPlace) => {
    try {
      const geocodeResult = await geocodeByPlaceId(selectedPlace.value.place_id);
      const latLng = await getLatLng(geocodeResult[0]);
      const { lat, lng } = latLng;
      setSelectedLocation({ lat: String(lat), lon: String(lng) });
    } catch (error) {
      console.error('Error while fetching coordinates:', error);
    }
  };
  
  
  const handleCloseClick = () => {
    setVisible(false);
  };

  const handleCloseClick1 = () => {
    setVisible1(false);
    setShowSuccessModal(false);
  };

  const handleVisibilityClick = (cost: number, service: string) => {
    setVisible(true);
    setSelectedServiceCost(cost);
    setSelectedService(service);
};

const handleVisibilityClick1 = (cost: number, service: string) => {
  setVisible1(true);
  setSelectedServiceCost(cost);
  setSelectedService(service);
};

  const startTimer = () => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
      setShowSuccessModal(false);
      setVisible(false);
      setTimer(5 * 60);
    }, 5 * 60 * 1000);
  };

  useEffect(() => {
    if (!showSuccessModal) {
      setTimer(5 * 60);
    }
  }, [showSuccessModal]);

  return (
    <>
    <Toaster />
      <div className="as_main_wrapper">
        <div className="container">
          <div className="row cta-buttons">
            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <div className="as_blog_box">
                <div className="as_blog_img">
                  <div className='d-flex flex-row'>
                    <a>
                      <img
                        src={astrologerData ? astrologerData.profile_photo : ''}
                        alt="Image"
                        className="img-responsive blog-imag"
                      />
                    </a>
                    <div className="ost-cols py-4 px-12">
                      <h5 className='mt-3'>{astrologerData ? astrologerData.name : ''}</h5>
                      <p>{astrologerData ? astrologerData.expertise.join(', ') : ''}</p>
                      <p>{astrologerData ? astrologerData.gender : ''}, {astrologerData ? astrologerData.city : ''}</p>
                      <div className='d-flex align-items-center justify-content-center gap-5'>
                        <h5 className='mt-3'><IoChatboxEllipses style={{ fontSize: "24px", color: "orange", marginTop: "-2px" }} /> - ₹{astrologerData ? astrologerData.chat_price : ''}/min</h5>
                        <h5 className='mt-3'><IoCall style={{ fontSize: "24px", color: "orange", marginTop: "-2px" }} /> - ₹{astrologerData ? astrologerData.voice_call_price : ''}/min</h5>
                      </div>
                    </div>
                  </div>
                  <div className="cta-buttonss as-btn">
                  {astrologerData && astrologerData.is_active ? (
                        <>
                        <button onClick={() => handleVisibilityClick(astrologerData.voice_call_price, 'ivr')} title='Make a Call'> <img src="/images/call.png" className="btn-ico mx-2" alt="" /> Call </button>
                        <button onClick={() => handleVisibilityClick1(astrologerData.voice_call_price, 'Chat')} title='Start a Chat'> <img src="/images/chat.png" className="btn-ico mx-2" alt="" /> Chat </button>
                        </>
                    ) : (
                        <>
                        <button style={{ pointerEvents: "none", opacity: "40%" }} title='Make a Call'> <img src="/images/call.png" className="btn-ico mx-2" alt="" /> Call </button>
                        <button style={{ pointerEvents: "none", opacity: "40%" }} title='Start a Chat'> <img src="/images/chat.png" className="btn-ico mx-2" alt="" /> Chat </button>
                        </>
                    )}
                      
                    </div>
                </div>
                <div className="as_blog_detail">
                  <h3 className="as_subheading">
                    <a href="javascript:;">About.</a>
                  </h3>
                  <p className="as_font14 as_margin0">{astrologerData ? astrologerData.bio : ''}</p>
                  <div className="as_padderTop30"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {visible && (
          <div className="overlay" style={{ ...overlayStyle, ...(visible && activeOverlayStyle) }}>
            <div style={contentStyle}>
              <div onClick={() => handleCloseClick()} style={{ backgroundColor: '#d3d3d3', padding: "9px 11px", position: "absolute", top: "14vh", right: "5vw", transform: "translate(-35%, -40%)", borderRadius: 20, cursor: 'pointer' }}>
                <CloseOutlined />
              </div>
              <h1 style={{color:"orange", fontSize:"5vh", paddingLeft:"15px"}} className='font-bold mx-auto'>User Information Form</h1>
                <hr
                  style={{
                    width: '100%',
                    borderTop: '2px dashed #7D3807',
                    borderBottom: '1px solid transparent',
                    margin: '10px',
                    background:"transparent",
                  }}
                />
                <Formik
                  initialValues={{
                    full_name: '',
                    time_of_birth: null as Date | null,
                    date_of_birth: null as Date | null,
                    place_of_birth: '',
                    gender: '',
                  }}
                  onSubmit={handleSubmit}
                >


              {({ values, setFieldValue }) => (
                <Form className='py-2 px-10 px-auto w-100  d-flex flex-row flex-wrap' noValidate id={`kt_create_account_form`}>
                  <div className='news'>
                    <label className='form-label fs-5 required'>Full Name</label>
                    <Field
                      name='full_name'
                      className='form-control form-control-lg form-control-solid newsa'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='full_name' />
                    </div>
                  </div>
                  <div className='news'>
                    <label className='form-label fs-5 required'>Time of Birth</label>
                    <TimePicker
                      name='time_of_birth'
                      className='form-control form-control-lg form-control-solid'
                      format="HH:mm"
                      placeholder="HH:mm"
                      style={{ border: '1px solid #d3d3d3', borderRadius: '10px', padding: '10px 20px', width: '90%', boxSizing: 'border-box' }}
                      value={values.time_of_birth ? dayjs(values.time_of_birth) : null}
                      onChange={(value) => {
                        const hours = value ? value.hour() : null;
                        const minutes = value ? value.minute() : null;
                        setFieldValue('hours', hours);
                        setFieldValue('minutes', minutes);
                        setFieldValue('time_of_birth', value?.toDate());
                      }}
                    />

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='time_of_birth' />
                    </div>
                  </div>
                  <div className='news'>
                    <label className='form-label fs-5 required'>Date of Birth</label>
                    <DatePicker
                      name='date_of_birth'
                      className='form-control form-control-lg form-control-solid'
                      style={{ border: '1px solid #d3d3d3', borderRadius: '10px', padding: '10px 20px', width: '90%', boxSizing: 'border-box' }}
                      value={values.date_of_birth ? dayjs(values.date_of_birth) : null}
                      onChange={(value) => setFieldValue('date_of_birth', value?.toDate())}
                      placeholder="YYYY-MM-DD"
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='date_of_birth' />
                    </div>
                  </div>
                  <div className='news'>
                    <label className='form-label fs-5 required'>Place of Birth</label>
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyCnkeFp8a1S0S5HHfxMg02JSmL8Sokasno"
                      autocompletionRequest={{
                        types: ['(regions)'],
                      }}
                      selectProps={{
                        name: 'place_of_birth',
                        placeholder: 'Enter your place of birth',
                        onChange: (selectedPlace) => handleLocationSelect(selectedPlace),
                      }}
                    />


                  </div>

                  <div className='news'>
                    <label className='form-label fs-5 required'>Gender</label>
                    <Field
                      as='select'
                      name='gender'
                      className='form-control form-control-lg form-control-solid newsa'
                    >
                      <option value="">Select Gender...</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='gender' />
                    </div>
                  </div>
                  
                  <div className='nesa'>
                    <button style={{background:"#7D3807", color:"#fff", bottom:"25px", position:"relative", width:"150px"}} type="submit" className="btn">Submit</button>
                  </div>
                </Form>
              )}
            </Formik>
            </div>
          </div>
        )}
        {visible1 && (
          <div className="overlay" style={{ ...overlayStyle, ...(visible1 && activeOverlayStyle) }}>
            <div style={contentStyle}>
              <div onClick={() => handleCloseClick1()} style={{ backgroundColor: '#d3d3d3', padding: "9px 11px", position: "absolute", top: "14vh", right: "5vw", transform: "translate(-35%, -40%)", borderRadius: 20, cursor: 'pointer' }}>
                <CloseOutlined />
              </div>
              <h1 style={{color:"orange", fontSize:"5vh", paddingLeft:"15px"}} className='font-bold mx-auto'>User Information Form</h1>
                <hr
                  style={{
                    width: '100%',
                    borderTop: '2px dashed #7D3807',
                    borderBottom: '1px solid transparent',
                    margin: '10px',
                    background:"transparent",
                  }}
                />
                <Formik
                  initialValues={{
                    full_name: '',
                    time_of_birth: null as Date | null,
                    date_of_birth: null as Date | null,
                    place_of_birth: '',
                    gender: '',
                  }}
                  onSubmit={handleSubmit1}
                >


              {({ values, setFieldValue }) => (
                <Form className='py-2 px-10 px-auto w-100  d-flex flex-row flex-wrap' noValidate id={`kt_create_account_form`}>
                  <div className='news'>
                    <label className='form-label fs-5 required'>Full Name</label>
                    <Field
                      name='full_name'
                      className='form-control form-control-lg form-control-solid newsa'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='full_name' />
                    </div>
                  </div>
                  <div className='news'>
                    <label className='form-label fs-5 required'>Time of Birth</label>
                    <TimePicker
                      name='time_of_birth'
                      className='form-control form-control-lg form-control-solid'
                      format="HH:mm"
                      placeholder="HH:mm"
                      style={{ border: '1px solid #d3d3d3', borderRadius: '10px', padding: '10px 20px', width: '90%', boxSizing: 'border-box' }}
                      value={values.time_of_birth ? dayjs(values.time_of_birth) : null}
                      onChange={(value) => {
                        const hours = value ? value.hour() : null;
                        const minutes = value ? value.minute() : null;
                        setFieldValue('hours', hours);
                        setFieldValue('minutes', minutes);
                        setFieldValue('time_of_birth', value?.toDate());
                      }}
                    />

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='time_of_birth' />
                    </div>
                  </div>
                  <div className='news'>
                    <label className='form-label fs-5 required'>Date of Birth</label>
                    <DatePicker
                      name='date_of_birth'
                      className='form-control form-control-lg form-control-solid'
                      style={{ border: '1px solid #d3d3d3', borderRadius: '10px', padding: '10px 20px', width: '90%', boxSizing: 'border-box' }}
                      value={values.date_of_birth ? dayjs(values.date_of_birth) : null}
                      onChange={(value) => setFieldValue('date_of_birth', value?.toDate())}
                      placeholder="YYYY-MM-DD"
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='date_of_birth' />
                    </div>
                  </div>
                  <div className='news'>
                    <label className='form-label fs-5 required'>Place of Birth</label>
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyCnkeFp8a1S0S5HHfxMg02JSmL8Sokasno"
                      autocompletionRequest={{
                        types: ['(regions)'],
                      }}
                      selectProps={{
                        name: 'place_of_birth',
                        placeholder: 'Enter your place of birth',
                        onChange: (selectedPlace) => handleLocationSelect(selectedPlace),
                      }}
                    />


                  </div>

                  <div className='news'>
                    <label className='form-label fs-5 required'>Gender</label>
                    <Field
                      as='select'
                      name='gender'
                      className='form-control form-control-lg form-control-solid newsa'
                    >
                      <option value="">Select Gender...</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='gender' />
                    </div>
                  </div>
                  
                  <div className='nesa'>
                    <button style={{background:"#7D3807", color:"#fff", bottom:"25px", position:"relative", width:"150px"}} type="submit" className="btn">Submit</button>
                  </div>
                </Form>
              )}
            </Formik>
            </div>
          </div>
        )}
        {showSuccessModal && (
        <div className="overlay" style={{ ...overlayStyle, ...(showSuccessModal && activeOverlayStyle) }}>
          <div style={contentStyle}>
            <div onClick={() => handleCloseClick1()} style={{ backgroundColor: '#d3d3d3', padding: "9px 11px", position: "absolute", top: "14vh", right: "5vw", transform: "translate(-35%, -40%)", borderRadius: 20, cursor: 'pointer' }}>
              <CloseOutlined />
            </div>
            <h1 style={{ color: "orange", fontSize: "5vh", paddingLeft: "15px" }} className='font-bold mx-auto'>Order Confirmed</h1>
            <hr
              style={{
                width: '100%',
                borderTop: '2px dashed #7D3807',
                borderBottom: '1px solid transparent',
                margin: '10px',
                background: "transparent",
              }}
            />
            <p>
              Your order for a consultation with an astrologer has been confirmed! Get ready for an insightful conversation that could illuminate your path. You'll receive a call from one of the following numbers: 01207176839, 01205166091, or 911204047396. Please ensure you're available to take the call. Prepare any questions or topics you'd like to discuss during the consultation. This session could provide valuable insights into your life's journey. Stay attentive, and make the most of this opportunity to gain clarity and guidance from a seasoned astrologer. We're excited to facilitate this enriching experience for you!
            </p>
          </div>
        </div>
      )}
      <section id="testimonials">
        <div className="testimonial-heading">
          <h4>Reviews</h4>
        </div>
        <div className="testimonial-box-container">
          {astrologerData?.reviews.map((review: Review) => (
            <div className="testimonial-box" key={review._id}>
              <div className="box-top">
                <div className="profile">
                  <div className="profile-img">
                    <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" alt="Profile" />
                  </div>
                  <div className="name-user">
                    <strong>{review.customerName}</strong>
                    <span>@{review.customerName.toLowerCase().replace(/\s/g, '')}</span>
                  </div>
                </div>
                <div className="reviews">
                  {Array.from({ length: review.rating }, (_, index) => (
                    <i className="fas fa-star" key={index}></i>
                  ))}
                  {Array.from({ length: 5 - review.rating }, (_, index) => (
                    <i className="far fa-star" key={index}></i>
                  ))}
                </div>
              </div>
              <div className="client-comment">
                <p>{review.review}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default Astrologerprofile;
