import React, { useState, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios'; // Import CancelTokenSource
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/LoaderHome';
import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import Astro from '../../components/astro';
import Astroo from '../../components/astroo';

type Props = {
    astrologerData: any;
};

interface Astrologer {
  _id: string;
  profile_photo: string;
  name: string;
  averageRating: number;
  totalCount: number;
  is_active: boolean;
  expertise: string[];
  language?: string | null;
  chat_price: number;
}

const Astrologersp: React.FC<Props> = ({
    astrologerData,
}) => {
    const [astrologers, setAstrologers] = useState<Astrologer[]>([]);
    const [astrologersf, setAstrologersf] = useState<Astrologer[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [astrologerDataa, setAstrologerDataa] = useState<Astrologer | null>(null);
    const navigate = useNavigate();
    const cancelTokenSourceRef = React.useRef<CancelTokenSource | null>(null); // Ref for cancel token source

    const navigateToDetails = (itemId: string) => {
        const item = astrologers.find(item => item._id === itemId);
        console.log("Item from astrologers:", item);
        if (item) {
            navigate(`/astrologerprofile/${itemId}`, { state: { astrologerDataa: item } });
        }
    };
    
    const navigateToDetailss = (itemId: string) => {
        const item = astrologersf.find(item => item._id === itemId);
        console.log("Item from astrologersf:", item);
        if (item) {
            navigate(`/astrologerprofile/${itemId}`, { state: { astrologerDataa: item } });
        }
    };

    const fetchAllAstrologers = async () => {
        try {
            const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdXBlcl9hZG1pbl91c2VyX2lkIjpudWxsLCJhc3Ryb2xvZ2VyX3VzZXJfaWQiOm51bGwsImN1c3RvbWVyX3VzZXJfaWQiOiI2NWU2YmE4MDRkNzA4NjUxZjc4NTVmNjQiLCJpYXQiOjE3MTQ1ODYyNDB9.QI3_XqN6wu7cSxQoGWdHkwB8KmDhYmUbEC-x87xKFT0";
            const source = axios.CancelToken.source(); // Create cancel token source
            cancelTokenSourceRef.current = source; // Set cancel token source ref
            const response = await axios.get('https://dev.astropath.co.in/customer/customer/get_all_astrologer', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: source.token // Set cancel token
            });
            setAstrologers(response.data.data);
        } catch (error) {
            if (!axios.isCancel(error)) { // Check if error is due to cancellation
                console.error('Error fetching all astrologers:', error);
            }
        }
    };

    const fetchFeaturedAstrologers = async () => {
        try {
            const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdXBlcl9hZG1pbl91c2VyX2lkIjpudWxsLCJhc3Ryb2xvZ2VyX3VzZXJfaWQiOm51bGwsImN1c3RvbWVyX3VzZXJfaWQiOiI2NWU2YmE4MDRkNzA4NjUxZjc4NTVmNjQiLCJpYXQiOjE3MTM1MDQ4MzJ9.1lXOFnnkMHw56O6cPv2oK2Tpx_mZee2CBHKWrpQruTw";
            const source = axios.CancelToken.source(); // Create cancel token source
            cancelTokenSourceRef.current = source; // Set cancel token source ref
            const response = await axios.get('https://dev.astropath.co.in/customer/customer/get_all_featured_astrologer', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: source.token // Set cancel token
            });
            setAstrologersf(response.data.data);
        } catch (error) {
            if (!axios.isCancel(error)) { // Check if error is due to cancellation
                console.error('Error fetching featured astrologers:', error);
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([fetchAllAstrologers(), fetchFeaturedAstrologers()]);
            setLoading(false);
        };

        fetchData();

        return () => { // Cleanup function
            if (cancelTokenSourceRef.current) {
                cancelTokenSourceRef.current.cancel("Component unmounted");
            }
        };
    }, []);

    return (
        <> 
            {loading ? (
                <Loader loading={loading} />
            ) : (
                <div className="">
                    <Navigation activePage="/astrologers" />
                    <Astroo astrologerData={astrologerData} />
                    <Footer />
                </div>
            )}
        </>
    );
}

export default Astrologersp;
