import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navigation from './Navigation';
import Footer from './Footer';
import Cookies from 'js-cookie';

interface PanchangData {
    day: string;
    sunrise: string;
    sunset: string;
    moonrise: string;
    moonset: string;
    vedic_sunrise: string;
    vedic_sunset: string;
    tithi: {
        details: {
            tithi_number: number;
            tithi_name: string;
            special: string;
            summary: string;
            deity: string;
        };
        end_time: {
            hour: number;
            minute: number;
            second: number;
        };
        end_time_ms: number;
    };
    nakshatra: {
        details: {
            nak_number: number;
            nak_name: string;
            ruler: string;
            deity: string;
            special: string;
            summary: string;
        };
        end_time: {
            hour: number;
            minute: number;
            second: number;
        };
        end_time_ms: number;
    };
    yog: {
        details: {
            yog_number: number;
            yog_name: string;
            special: string;
            meaning: string;
        };
        end_time: {
            hour: number;
            minute: number;
            second: number;
        };
        end_time_ms: number;
    };
    karan: {
        details: {
            karan_number: number;
            karan_name: string;
            special: string;
            deity: string;
        };
        end_time: {
            hour: number;
            minute: number;
            second: number;
        };
        end_time_ms: number;
    };
    hindu_maah: {
        adhik_status: boolean;
        purnimanta: string;
        amanta: string;
        amanta_id: number;
        purnimanta_id: number;
    };
    paksha: string;
    ritu: string;
    sun_sign: string;
    moon_sign: string;
    ayana: string;
    panchang_yog: string;
    vikram_samvat: number;
    shaka_samvat: number;
    vkram_samvat_name: string;
    shaka_samvat_name: string;
    disha_shool: string;
    disha_shool_remedies: string;
    moon_nivas: string;
    rahukaal: {
        start: string;
        end: string;
    };
    guliKaal: {
        start: string;
        end: string;
    };
    yamghant_kaal: {
        start: string;
        end: string;
    };
}



const Panchangi = () => {
    const [panchangData, setPanchangData] = useState<PanchangData | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = Cookies.get('token');
                const response = await fetch('https://dev.astropath.co.in/kundali/backend/advanced_panchang', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        name: 'user',
                        day: new Date().getDate(),
                        month: new Date().getMonth() + 1,
                        year: new Date().getFullYear(),
                        hour: new Date().getHours(),
                        min: new Date().getMinutes(),
                        lat: '28.7041',
                        lon: '77.1025',
                        tzone: '5.5',
                    }),
                });
                const data = await response.json();
                setPanchangData(data.data);
            } catch (error) {
                console.error('Error fetching panchang data:', error);
            }
        };

        fetchData();
    }, []);


   
  return (
    <> 
      <div style={{paddingBottom:"100px", marginTop:"0px"}} className="container yoyo">
      <h1 style={{ textTransform: 'capitalize', color:"orange", fontSize:"50px" }}>Panchang</h1>
        <hr style={{
          width:"100%",
          position:"relative",
          zIndex:"-1",
          border: 0,
          height: "1px",
          backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0))"
        }} />
        <div>
        {panchangData && (
            <div className='d-flex flex-wrap justify-content-center align-items-center'>
            <div className='col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center'>
                <div className='astro-cont4'>
                    <div className='d-flex flex-column px-4 justify-content-start'>
                        <h1 style={{color:"#f1981c", fontWeight:"600", fontSize:"35px"}}>Monday <span style={{fontSize:"16px", color:"#111111"}}>13th May 2024</span></h1>
                        <hr style={{
                            width:"100%",
                            position:"relative",
                            zIndex:"-1",
                            border: 0,
                            height: "1px",
                            backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0))"
                        }} />
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <img className='pci' src="/images/sunrise.png" alt="" />
                                <p style={{margin:"0"}}>Vedic Sunrise</p>
                                <p>{panchangData.vedic_sunrise}</p>
                            </div>
                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <img className='pci' src="/images/sunset.png" alt="" />
                                <p style={{margin:"0"}}>Vedic Sunset</p>
                                <p>{panchangData.vedic_sunset}</p>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <img className='pci' src="/images/moon.png" alt="" />
                                <p style={{margin:"0"}}>Moonrise</p>
                                <p>{panchangData.moonrise}</p>
                            </div>
                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <img className='pci' src="/images/moon.png" alt="" />
                                <p style={{margin:"0"}}>Moonset</p>
                                <p>{panchangData.moonset}</p>
                            </div>
                        </div>
                        <hr style={{ backgroundColor: 'orange', height: '2px', border: 'none' }} />
                        <div className='d-flex flex-column mt-4'>
                            <h1 style={{color:"#f1981c", fontWeight:"600", fontSize:"20px"}}>Ritu : <span style={{fontSize:"18px", color:"rgb(156 156 156)"}}> {panchangData.ritu}</span></h1>
                            <h1 style={{color:"#f1981c", fontWeight:"600", fontSize:"20px"}}>Ayana : <span style={{fontSize:"18px", color:"rgb(156 156 156)"}}> {panchangData.ayana}</span></h1>
                            <h1 style={{color:"#f1981c", fontWeight:"600", fontSize:"20px"}}>Disha Shool : <span style={{fontSize:"18px", color:"rgb(156 156 156)"}}> {panchangData.disha_shool}</span></h1>
                            <h1 style={{color:"#f1981c", fontWeight:"600", fontSize:"20px"}}>Shaka Samvat : <span style={{fontSize:"18px", color:"rgb(156 156 156)"}}> {panchangData.shaka_samvat}</span></h1>
                            <h1 style={{color:"#f1981c", fontWeight:"600", fontSize:"20px"}}>Vikram Samvant : <span style={{fontSize:"18px", color:"rgb(156 156 156)"}}> {panchangData.vikram_samvat}</span></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 d-flex flex-column p-12 justify-content-center'>
            {/* <table className='basic'>
                <tbody>
                    {Object.entries(panchangData).map(([key, value]) => (
                        <tr key={key}>
                            <td className='capital' data-label="Title">{key}</td>
                            <td className='capital' data-label="Description">{typeof value === 'object' ? JSON.stringify(value) : value}</td>
                        </tr>
                    ))}
                </tbody>
            </table> */}
            <h1 style={{fontSize:"32px", color:"orange"
            }}>
                Panchang
            </h1>
            <p>
                Daily Panchang refers to the comprehensive astrological almanac that provides essential information for the day based on Hindu astrology. It typically includes details such as Tithi (lunar day), Nakshatra (lunar mansion), Yoga (auspicious period), Karana (half of a lunar day), sunrise, sunset, moonrise, moonset, and planetary positions. These elements are crucial for determining auspicious timings for various activities like weddings, ceremonies, and rituals.

                Moreover, Daily Panchang also highlights the Rashi (zodiac sign), Ayana (sun's apparent movement), Ritu (season), and other astronomical phenomena. It serves as a guide for individuals seeking to align their actions with celestial energies, ensuring harmony and success in their endeavors. By consulting the Daily Panchang, individuals can make informed decisions about auspicious timings for significant events, thereby enhancing the prospects of favorable outcomes in their endeavors. Overall, Daily Panchang acts as a spiritual and practical tool, offering insights into cosmic influences and guiding individuals towards a balanced and prosperous life.
            </p>
            </div>
            </div>
         )}
        </div>
      </div>
    </>
  );
}

export default Panchangi;
