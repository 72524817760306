import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navigation from '../../components/Navigation';

enum Tabs {
  Yesterday,
  Today,
  Tomorrow,
  Monthly,
}

interface Prediction {
  personal_life: string;
  profession: string;
  health: string;
  emotions: string;
  travel: string;
  luck: string;
}

interface ZodiacSign {
  name: string;
  image: string;
}

const HoroscopeInner: React.FC = () => {
  const [sunSign, setSunSign] = useState('');
  const [selectedTimezone, setSelectedTimezone] = useState('+5.30');
  const [prediction, setPrediction] = useState<Prediction | null>(null);
  const [previousPrediction, setPreviousPrediction] = useState<Prediction | null>(null);
  const [nextPrediction, setNextPrediction] = useState<Prediction | null>(null);
  const [visible, setVisible] = useState(false);
  const [selectedTabs, setSelectedTabs] = useState<Tabs>(Tabs.Today);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const zodiacSigns: ZodiacSign[] = [
    { name: 'Aries', image: '/images/horo12.png' },
    { name: 'Taurus', image: '/images/horo11.png' },
    { name: 'Gemini', image: '/images/horo10.png' },
    { name: 'Cancer', image: '/images/horo9.png' },
    { name: 'Leo', image: '/images/horo8.png' },
    { name: 'Virgo', image: '/images/horo7.png' },
    { name: 'Libra', image: '/images/horo6.png' },
    { name: 'Scorpio', image: '/images/horo5.png' },
    { name: 'Sagittarius', image: '/images/horo4.png' },
    { name: 'Capricorn', image: '/images/horo3.png' },
    { name: 'Aquarius', image: '/images/horo2.png' },
    { name: 'Pisces', image: '/images/horo1.png' }
  ];

  const handleFetchData = async (zodiacSign: string) => {
    try {
      const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdXBlcl9hZG1pbl91c2VyX2lkIjpudWxsLCJhc3Ryb2xvZ2VyX3VzZXJfaWQiOm51bGwsImN1c3RvbWVyX3VzZXJfaWQiOiI2NWU2YmE4MDRkNzA4NjUxZjc4NTVmNjQiLCJpYXQiOjE3MTQ1ODYyNDB9.QI3_XqN6wu7cSxQoGWdHkwB8KmDhYmUbEC-x87xKFT0";
      const [previousResponse, nextResponse, dailyResponse] = await Promise.all([
        fetch('https://dev.astropath.co.in/kundali/backend/sun_sign_prediction/daily/previous', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            zodiacName: zodiacSign,
            tzone: selectedTimezone
          })
        }),
        fetch('https://dev.astropath.co.in/kundali/backend/sun_sign_prediction/daily/next', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            zodiacName: zodiacSign,
            tzone: selectedTimezone
          })
        }),
        fetch('https://dev.astropath.co.in/kundali/backend/sun_sign_prediction/daily', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            zodiacName: zodiacSign,
            tzone: selectedTimezone
          })
        })
      ]);
  
      const [previousData, nextData, dailyData]: [any, any, any] = await Promise.all([
        previousResponse.json(),
        nextResponse.json(),
        dailyResponse.json()
      ]);
      setSunSign(zodiacSign);
      setPreviousPrediction(previousData.data.prediction);
      setNextPrediction(nextData.data.prediction);
      setPrediction(dailyData.data.prediction);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const initialSelectedSign = location.state ? (location.state as any).selectedSign : '';

  useEffect(() => {
    if (initialSelectedSign) {
      handleFetchData(initialSelectedSign);
    }
  }, [initialSelectedSign]);

  const handleSignClick = (signName: string) => {
    handleFetchData(signName);
  };

  return (
    <>
      <Navigation activePage="" />
      <div className="wrapper desktops yoyosas">
        {zodiacSigns.map((sign, index) => (
          <div key={index} className={`icon sunsign`} onClick={() => handleSignClick(sign.name)}>
            <div className="tooltip">{sign.name}</div>
            <span className={sunSign === sign.name ? 'activesign' : ''}><img src={sign.image} alt={sign.name} /></span>
          </div>
        ))}
      </div>

      <div className="wrapper mobile yoyosas">
        {zodiacSigns.map((sign, index) => (
          <div key={index} className={`icon sunsign`} onClick={() => handleSignClick(sign.name)}>
            <span className={sunSign === sign.name ? 'activesign' : ''}><img src={sign.image} alt={sign.name} /></span>
          </div>
        ))}
      </div>

      <div className='yoyosa container my-12'>
        <h1 style={{ textTransform: 'capitalize', color:"orange", fontSize:"50px" }}>{sunSign}</h1>
        <hr style={{
          width:"100%",
          position:"relative",
          zIndex:"-1",
          border: 0,
          height: "1px",
          backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0))"
        }} />
        <div className="detailing {activeTab === 'Today'}">
          <div className="taba">
            <div onClick={() => setSelectedTabs(Tabs.Yesterday)} className={`tabs ${selectedTabs === Tabs.Yesterday ? 'activeTab' : ''}`}>
              Yesterday
            </div>
            <div onClick={() => setSelectedTabs(Tabs.Today)} className={`tabs ${selectedTabs === Tabs.Today ? 'activeTab' : ''}`}>
              Today
            </div>
            <div onClick={() => setSelectedTabs(Tabs.Tomorrow)} className={`tabs ${selectedTabs === Tabs.Tomorrow ? 'activeTab' : ''}`}>
              Tomorrow
            </div>
          </div>
        </div>
        {selectedTabs === Tabs.Today && (
          <div className='tabs-content'>
            <p> <h2>Personal Life</h2> {prediction && prediction.personal_life}</p>
            <p> <h2>Profession</h2>{prediction && prediction.profession}</p>
            <p> <h2>Prediction</h2>{prediction && prediction.health}</p>
            <p> <h2>Emotions</h2>{prediction && prediction.emotions}</p>
            <p> <h2>Travel</h2>{prediction && prediction.travel}</p>
            <p> <h2>Luck</h2>{prediction && prediction.luck}</p>
          </div>
        )}
        {selectedTabs === Tabs.Yesterday && (
          <div className='tabs-content'>
            <p> <h2>Personal Life</h2>{previousPrediction && previousPrediction.personal_life}</p>
            <p> <h2>Profession</h2>{previousPrediction && previousPrediction.profession}</p>
            <p> <h2>Prediction</h2>{previousPrediction && previousPrediction.health}</p>
            <p> <h2>Emotions</h2>{previousPrediction && previousPrediction.emotions}</p>
            <p> <h2>Travel</h2>{previousPrediction && previousPrediction.travel}</p>
            <p> <h2>Luck</h2>{previousPrediction && previousPrediction.luck}</p>
          </div>
        )}
        {selectedTabs === Tabs.Tomorrow && (
          <div className='tabs-content'>
            <p> <h2>Personal Life</h2>{nextPrediction && nextPrediction.personal_life}</p>
            <p> <h2>Profession</h2>{nextPrediction && nextPrediction.profession}</p>
            <p> <h2>Prediction</h2>{nextPrediction && nextPrediction.health}</p>
            <p> <h2>Emotions</h2>{nextPrediction && nextPrediction.emotions}</p>
            <p> <h2>Travel</h2>{nextPrediction && nextPrediction.travel}</p>
            <p> <h2>Luck</h2>{nextPrediction && nextPrediction.luck}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default HoroscopeInner;
