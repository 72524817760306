import React, { Component, CSSProperties } from 'react';
import { toAbsoluteUrl } from '../../_metronic/helpers';

interface LoaderProps {
  loading: boolean;
}

class Loader extends Component<LoaderProps> {
  render() {


    return (
      <div className="as_loader">
        <img src="/images/loader.png" alt="" className="img-responsive" />
      </div>
    );
  }
}

export default Loader;
