import React, { useState, useEffect, Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { AuthInit } from './modules/auth';
import { ThemeModeProvider } from '../_metronic/partials';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OfferModal from './components/Offers';
import Cookies from 'js-cookie';

const App = () => {
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [bannerUrl, setBannerUrl] = useState('');
  const location = useLocation();

  useEffect(() => {
    // Function to fetch banner URL from API
    const twoHoursFromNow = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
    const fetchBannerUrl = async () => {
      try {
        const response = await fetch('/api/banner'); // API endpoint for banner
        const data = await response.json();
        setBannerUrl(data.url); // Assuming response has a 'url' field
      } catch (error) {
        console.error('Error fetching banner URL:', error);
      }
    };

    // Function to show modal
    const checkAndShowModal = () => {
      setShowOfferModal(true);
    };

    // First load check
    if (!Cookies.get('offerModalShown')) {
      Cookies.set('offerModalShown', 'true', { expires: twoHoursFromNow });
      checkAndShowModal();
    }

    // Fetch banner URL on component mount
    fetchBannerUrl();

    // Every 4 minutes check
    const interval = setInterval(() => {
      checkAndShowModal();
    }, 240000); // 4 minutes in milliseconds

    return () => clearInterval(interval);
  }, [location]);

  const handleCloseOfferModal = () => {
    setShowOfferModal(false);
  };

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
              {showOfferModal && <OfferModal show={showOfferModal} onHide={handleCloseOfferModal} bannerUrl={bannerUrl} />}
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
        <ToastContainer />
      </I18nProvider>
    </Suspense>
  );
};

export { App };
