import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';

const Contact = () => {
  const location = useLocation();  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  return (
    <> 
    <div className="">
      <Navigation activePage="/contact" />

      <section className="as_breadcrum_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1>Contact Us</h1>

              <ul className="breadcrumb">
                <li><a href="/">Home</a></li>
                <li>Contact</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="as_contact_wrapper as_padderBottom40 as_padderTop50">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="as_heading as_heading_center">
                get in touch with us!
              </h1>
              <span
                ><svg
                  width="240"
                  height="15"
                  viewBox="0 0 240 15"
                >
                  <image
                    id="Vector_Smart_Object"
                    data-name="Vector Smart Object"
                    width="240"
                    height="15"
                  />
                </svg>
              </span>
              <p className="as_font14 as_padderBottom50 as_padderTop20">
                Get in touch with us today to embark on your cosmic journey. Our team of astrologers is here to provide personalized <br /> guidance and support. Reach out via email, phone, or our online contact form to schedule a consultation and unlock the wisdom of the stars.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="as_contact_detail">
                <ul>
                  <li className="as_info_box">
                    <span className="as_icon">
                      <img src="/images/svg/map.svg" alt="" />
                    </span>
                    <p>982, Sector 15, Vasundhra, Ghaziabad, 201012 Uttar Pradesh</p>
                  </li>
                  <li className="as_info_box">
                    <span className="as_icon">
                      <img src="/images/svg/phone.svg" alt="" />
                    </span>
                    <p>+91 8527-200-764</p>
                  </li>
                  <li className="as_info_box">
                    <span className="as_icon">
                      <img src="/images/svg/mail.svg" alt="" />
                    </span>
                    <p>
                      <a href="javascript:;"
                        >info@astropath.com</a>
                    </p>
                  </li>
                </ul>
                <div className="as_map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28010.117212877056!2d77.33722037100547!3d28.651793402674574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfab835dc1a3f%3A0xae4fd6575b5ce44!2sGhaziabad%2C%20Uttar%20Pradesh%20201012%2C%20India!5e0!3m2!1sen!2sus!4v1711644981616!5m2!1sen!2sus"
                    width="100%"
                    height="318px"
                    style={{border:"0"}}
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <form className="as_appointment_form">
                <div className="form-group">
                  <input
                    type="text"
                    name=""
                    value=""
                    placeholder="Name"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name=""
                    value=""
                    placeholder="Last Name"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name=""
                    value=""
                    placeholder="Email Address"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Subject"
                    className="form-control"
                  />
                </div>
                <div className="form-group as_padderBottom10">
                  <textarea
                    name=""
                    className="form-control"
                    placeholder="Message"
                    id=""
                  ></textarea>
                </div>
                <button className="as_btn">Send Message</button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
    </>
  )
}

export default Contact;