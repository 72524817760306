import React, { useState, useEffect, useRef } from 'react';
import { ZIM } from 'zego-zim-web';
import { ZIMMessageQueryConfig } from 'zego-zim-web';
import CryptoJS from 'crypto-js';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';

interface ZIMLoginConfig {
    userID: string;
    userName: string;
}

interface ZIMMessage {
    id: string;
    sender: string;
    content: string;
    timestamp: number;
}

interface ChatMainProps {
    selectedChat: {
        astrologerPhone: string;
        status: string;
    };
}



const ChatMain: React.FC<ChatMainProps> = ({ selectedChat }) => {
    const [messages, setMessages] = useState<ZIMMessage[]>([]);
    const [messageText, setMessageText] = useState('');
    const [userid, setUserid] = useState('');
    const [image, setImage] = useState('');
    const [username, setUsername] = useState('');
    const scrollViewRef = useRef<HTMLDivElement | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [token, setToken] = useState('04AAAAAGZB4/wAEGlnOHc4c2NoeTJoNDgya3AAgPhevlXPo7NZtGoqIH8BAOxBZQBcWJUrxZ9RwItuCOX0MPahCZvHtMMo5WvfEe/KCk4TSDZhUucCA9kVmifjfflnJhM9jwjFqORytCU2BlPCdb8YW+pjhfTbmdEWIceRsuRTOCGpao5nneHVavxl1H7YVg5ztvNXLBOEfFFSFRHo');
    const [zimInstance, setZimInstance] = useState<any>(null);
    const cameraRef = useRef(null);
    const [isUploading, setIsUploading] = useState(false);
    const location = useLocation();
    const [isLoginChecked, setIsLoginChecked] = useState(false);
    const [historyResponse, setHistoryResponse] = useState<any>(null);


    const { apiData } = location.state as any;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = Cookies.get('token');
                const response = await axios.get('https://dev.astropath.co.in/customer/customer/profile', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });
                const phone = response.data.phone;
                const name = response.data.name;
                const image = response.data.profile_photo;
                const response1 = await axios.post('https://dev.astropath.co.in/backend/zego_token', {
                    userId:response.data.phone
                  },{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                                });
                
                setUserid(phone);
                setUsername(name);
                setImage(image)
                setToken(response1.data.token)
            } catch (error) {
                console.error('Error fetching profile data:', error);
            } finally {
                setIsLoading(false);
                setIsLoginChecked(true);
            }
        };

        fetchData();
    }, []);

    var appConfig = {
        appID: 1759920386,
        serverSecret: process.env.REACT_APP_SERVER_SECRET || '',

    };

    useEffect(() => {
        if (!isLoginChecked || !userid || !token) return;

        const appID = 1759920386;
        const serverSecret = process.env.REACT_APP_SERVER_SECRET || '';
        const ph = '8840200147'
        const tk = '04AAAAAGZB5CEAEDg2czBkZ3dhd2xycDQyeDMAgNtaz7BAZ706Ujw8Pj0w6hpXRjeRzIE8O1sqKdVyOIwCCwPhEHlUmAoTkIuA6fo9TtgCHsVAXpNX6hRMopnotGoMZssJlT0o4k6TUXjXlDtIq00S6RSLRNO/6rupTqLFthZVQA1sTrEpBhPRJAU/I+Rkm70VvpDEANg+67qdqJVg'

        ZIM.create({ appID });
        const zim = ZIM.getInstance();
        const userInfo: ZIMLoginConfig = {
            userID: userid,
            userName: username
            
        };
        console.log(userid,'token:',token);

        zim.login(userInfo as any, token as any)
        .then(() => {
            console.log('Login Successful');
            setZimInstance(zim);
            fetchHistory(true);
        })
        .catch((err) => {
            console.log('Login Failed ', err);
        });

        
        zim.on('tokenWillExpire', function(zim, second) {
            axios.get('https://dev.astropath.co.in/gateway/zego_token')
                .then(response => {
                    const token = response.data.token;
                    console.log('Token from backend:', token);
                    zim.renewToken(token)
                        .then(function({ token }) {
                            console.log('Token renewed successfully:', token);
                        })
                        .catch(function(err) {
                            console.error('Failed to renew the Token:', err);
                        });
                })
                .catch(error => console.error('Error fetching Zego token:', error));
        });
        

        return () => {
            zim.off('receivePeerMessage');
        };
    }, [isLoginChecked, userid]);
    
    const zim = ZIM.getInstance();
  
    const fetchHistory = async (fetchAll: boolean) => {
        if (!zim) return;
      
        const count = 300;
        const configs: ZIMMessageQueryConfig = {
            nextMessage: undefined,
            count: count,
            reverse: true,
        };
    
        const type = 0;
        try {
            const response = await zim.queryHistoryMessage(apiData.astrologerPhone, type, configs);
            console.log(response);
            setHistoryResponse(response);
            if (response.messageList && response.messageList.length > 0) {
                const newMessages: ZIMMessage[] = response.messageList.map((message: any) => ({
                    id: message.id,
                    sender: message.senderUserID,
                    content: message.message,
                    timestamp: message.timestamp,
                    prevMessages: message.prevMessages,
                    messageList: message.messageList,
                }));
    
                if (fetchAll) {
                    setMessages(newMessages);
                } else {
                    setMessages(prevMessages => {
                        const existingIds = prevMessages.map(message => message.id);
                        const uniqueNewMessages = newMessages.filter(message => !existingIds.includes(message.id));
                        return [...prevMessages, ...uniqueNewMessages];
                    });
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Failed to fetch history:', error);
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        fetchHistory(true);
    }, []);
    

    const handleSendMessage = () => {
        if (!zimInstance) {
            console.log('ZIM instance not available');
            return;
        }

        const toConversationID = apiData.astrologerPhone;
        const conversationType = 0;
        const config = {
            priority: 1,
        };

        const messageTextObj = { type: 1, message: messageText, extendedData: '' };
        const notification = {
            onMessageAttached: function (message: any) {
            }
        }

        zimInstance.sendMessage(messageTextObj, toConversationID, conversationType, config, notification)
            .then(({ message }: any) => {
                setMessageText('');
                setMessages(prevMessages => [...prevMessages, message]);
            })
            .catch((err: any) => {
            });
    };

    const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessageText(event.target.value);
    };

    useEffect(() => {
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <div className="as_main_wrapper">
            <section>
                <main className="content">
                    <div className="container p-0">
                        <div style={{borderRadius:"20px", background:"#fff", width:"100%"}} className="col-lg-6 col-xl-6">
                            <div className="py-2 px-4 border-bottom d-none d-lg-block">
                                <div className="d-flex align-items-center py-1">
                                    <div className="position-relative">
                                        <img style={{maxWidth:"50px", height:"50px"}} src={apiData.astrologerProfile} className="rounded-circle mr-1" alt="Laxit" width="50"  />
                                    </div>
                                    <div className="flex-grow-1 pl-3">
                                        <strong>Talking to {apiData.astrologerName}</strong>
                                    </div>
                                </div>
                            </div>

                            <div style={{ height: "65vh" }} className="position-relative">
                                <div className="chat-messages p-4">
                                    {messages.map((message, index) => (
                                        <div key={index} className={`chat-message-${message.sender === userid ? 'right' : 'left'} pb-4`}>
                                        <div>
                                            {message.sender === userid ? (
                                                <img style={{maxWidth:"50px", height:"50px"}} src={image} className="rounded-circle mr-1" alt="User" width="50"/>
                                            ) : (
                                                <img style={{maxWidth:"50px", height:"50px"}} src={apiData.astrologerProfile} className="rounded-circle mr-1" alt="Astrologer" width="50"/>
                                            )}
                                            <div className="text-muted small text-nowrap mt-2">{new Date(message.timestamp).toLocaleTimeString()}</div>
                                        </div>
                                        <div style={{ height: "fit-content" }} className={`flex-shrink-1 bg-light rounded py-2 px-3 mr-3`}>
                                            <div className="font-weight-bold mb-1">{message.content}</div>
                                        </div>
                                    </div>
                                    
                                    ))}
                                </div>
                            </div>

                            <div className="flex-grow-0 py-3 px-4 border-top">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Type your message" value={messageText} onChange={handleMessageChange} />
                                    <button className="btn btn-primary" onClick={handleSendMessage}>Send</button>
                                </div>
                            </div>
                            

                        </div>
                    </div>
                </main>
            </section>
        </div>    
    );
}

export default ChatMain;
