import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import Loader from '../../components/LoaderHome';

interface Order {
    cost: number;
    customerName: string;
    date: string;
    service: string;
    startTime: string;
    endTime: string;
    status: string;
}

const Orders = () => {
    const [orders, setOrders] = useState<Order[]>([]);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = async () => {
        try {
            const token = Cookies.get('token');
            const response = await axios.get<{ data: Order[] }>('https://dev.astropath.co.in/customer/customer/get_orders', {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            const responseData = response.data.data; 
            console.log("sdf", responseData)
            setOrders(responseData);
        } catch (error) {
            console.error('Error fetching Transaction data:', error);
        } finally {
            setLoading(false); 
        }
    };

    const calculateDuration = (startTime: string, endTime: string) => {
        const start = new Date(startTime);
        const end = new Date(endTime);
        const durationInSeconds = (end.getTime() - start.getTime()) / 1000;

        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = Math.floor(durationInSeconds % 60);

        if (minutes > 0) {
            return `${minutes} mins ${seconds} secs`;
        } else {
            return `${seconds} secs`;
        }
    };

    if (loading) {
        return <Loader loading={loading} />;
    }

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        let dayWithSuffix;
        if (day === 1 || day === 21 || day === 31) {
            dayWithSuffix = `${day}st`;
        } else if (day === 2 || day === 22) {
            dayWithSuffix = `${day}nd`;
        } else if (day === 3 || day === 23) {
            dayWithSuffix = `${day}rd`;
        } else {
            dayWithSuffix = `${day}th`;
        }

        return `${dayWithSuffix} ${month} ${year}`;
    };

    return (
        <>
            <div className="">
                <section>
                    <div className="container">
                        <div className="row d-flex justify-content-center align-items-center">
                            {orders.map((order, index) => (
                                <div className="col-lg-4 col-md-6 d-flex justify-content-center align-items-center" key={index}>
                                    <div className="astro-cont d-flex align-items-center justify-content-between">
                                        <div className="ost-cols15">
                                            <h5>{order.customerName}</h5>
                                            <p style={{fontSize:"13px", marginBottom:"0"}}>Date - {formatDate(order.date)}</p>
                                            <p className='capitalize'>Service - {order.service}</p>
                                            <h5>Total Cost - <span style={{color:"rgb(148 148 148)"}}>₹{order.cost}</span></h5>
                                        </div>
                                        <div style={{height:"80%"}} className="cont-div54">
                                            <div className="verify1">
                                                <h6 style={{color:"#f1991c", fontSize:"18px"}}>Duration</h6>
                                                <p style={{fontSize:"14px"}}>{calculateDuration(order.startTime, order.endTime)}</p>
                                            </div>
                                            <div className="cta-buttons">
                                                <span style={{background:"brown"}} className='badge badge-primary'>{order.status}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Orders;
