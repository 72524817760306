import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IoVideocam, IoCall, IoChatboxEllipses } from "react-icons/io5";
import Navigation from '../../components/Navigation';
import OtpInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import indiaFlag from '../../../_metronic/assets/card/flag.jpg'
import Cookies from 'js-cookie';
import toast, { Toaster } from 'react-hot-toast';

type Props = {
  astrologerDataa: any;  // Add this line
}

const AstrologerInnerp: React.FC<Props> = ({
  astrologerDataa,
}) => {
  const [show, setShow] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [orderId, setOrderId] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseVerifyModal = () => setShowVerifyModal(false);
  const handleShowVerifyModal = () => setShowVerifyModal(true);


  const location = useLocation();
  const receivedData: any = location.state as any;
  useEffect(() => {
    }, [astrologerDataa]);

    const sendOTP = async (phoneNumber: string) => {
      try {
        const formattedPhoneNumber = '+91' + phoneNumber;
        const response = await fetch('https://dev.astropath.co.in/backend/customer/otp', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ phone: formattedPhoneNumber }),
        });
      
        if (response.status === 200) {
          const data = await response.json();
          const orderIdFromResponse = data.payload.orderId; 
          setOrderId(orderIdFromResponse); 
          handleShowVerifyModal();
        } else {
          console.error('Error sending OTP. Status code:', response.status);
        }
      } catch (error) {
        console.error('Error sending OTP:', error);
      }
      
    };
  
  
    const verifyOTP = async (otp: string) => {
      try {
        const formattedPhoneNumber = '+91' + phoneNumber;
        const response = await fetch('https://dev.astropath.co.in/backend/customer/otpVerify', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ otp, phone: formattedPhoneNumber, orderId }),
        });
    
        if (response.status === 200) {
          const data = await response.json();
          const token = data.jwt_token;
          const userId = data.existingCustomer._id;
          handleCloseVerifyModal();
          Cookies.set('isLoggedIn', 'true', { expires: 7 });
          Cookies.set('token', token, { expires: 15 });
          Cookies.set('userId', userId, { expires: 15 });
          setTimeout(() => {
            window.location.href = '/customer/account';
          }, 400);
        } else {
          const errorData = await response.json();
          console.log("dbs", errorData)
          if (errorData.response.reason === "OTP Expired!") {
            toast.error("OTP has expired. Please request a new OTP.")
          } else {
            console.error('Error verifying OTP. Status code:', response.status);
          }
        }
      } catch (error) {
        toast.error("OTP has expired. Please request a new OTP.");
      }
    };
  
    const handleResendOTP = async (formikProps) => {
      try {
        const response = await fetch('https://dev.astropath.co.in/backend/customer/resendOtp', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ orderId: orderId }),
        });
        const data = await response.json();
        if (data.success) {
          for (let i = 1; i <= 6; i++) {
            formikProps.setFieldValue(`digit${i}`, '');
          }
        } else {
          console.error('Error resending OTP:', data.message);
        }
      } catch (error) {
        console.error('Error resending OTP:', error);
      }
    };
  

  return (
    <> 
      <div className="as_main_wrapper">
      <Navigation activePage="" />
      <section>
          <div className="container yoyo">
            <div className="row cta-buttons">
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                <div className="as_blog_box">
                  <div className="as_blog_img">
                    <div className='d-flex flex-row'>
                      <a>
                        <img
                          src={receivedData.astrologerDataa.profile_photo}
                          alt=""
                          className="img-responsive blog-imag"
                        />
                      </a>
                      <div className="ost-cols10 py-4 px-12">
                        <h5 className='mt-3'>{receivedData.astrologerDataa.name}</h5>
                        <p>{receivedData.astrologerDataa.expertise.join(', ')}</p>
                        <p>{receivedData.astrologerDataa.gender}, {receivedData.astrologerDataa.city}</p>
                        <p>Exp - {receivedData.astrologerDataa.experience} yrs</p>
                        <div className='d-flex align-items-center justify-content-start flex-wrap gap-5'>
                          <h6 className='mt-3'><IoChatboxEllipses style={{fontSize:"24px", color:"orange", marginTop:"-2px"}} /> - ₹{receivedData.astrologerDataa.chat_price}/min</h6>
                          <h6 className='mt-3'><IoCall style={{fontSize:"24px", color:"orange", marginTop:"-2px"}} /> - ₹{receivedData.astrologerDataa.voice_call_price}/min</h6>
                          {/* <h6 className='mt-3'><IoVideocam style={{fontSize:"24px", color:"orange", marginTop:"-2px"}} /> - ₹{receivedData.astrologerDataa.video_call_price}/min</h6> */}
                        </div>
                      </div>
                    </div>                      
                    <div className="cta-buttonss as-btn">
                      <button onClick={handleShow} title='Make a Call'> <img src="/images/call.png" className="btn-ico mx-2" alt="" /> Call </button>
                      <button onClick={handleShow} title='Start a Chat'> <img src="/images/chat.png" className="btn-ico mx-2" alt="" /> Chat </button>
                    </div>
                  </div>
                  <div className="as_blog_detail">
                    <h3 className="as_subheading">
                      <a href="javascript:;">About.</a>
                    </h3>
                    <p className="as_font14 as_margin0">{receivedData.astrologerDataa.bio}</p>
                    <div className="as_padderTop30"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="testimonials">
        <div className="testimonial-heading">
            <h4>Reviews</h4>
        </div>
        <div className="testimonial-box-container">
          
        {receivedData.astrologerDataa.reviews.map((review: any) => (
              <div className="testimonial-box" key={review._id}>
                <div className="box-top">
                  <div className="profile">
                    <div className="profile-img">
                      <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" alt="Profile" />
                    </div>
                    <div className="name-user">
                      <strong>{review.customerName}</strong>
                      <span>@{review.customerName.toLowerCase().replace(/\s/g, '')}</span>
                    </div>
                  </div>
                  <div className="reviews">
                    {Array.from({ length: review.rating }, (_, index) => (
                      <i className="fas fa-star" key={index}></i>
                    ))}
                    {Array.from({ length: 5 - review.rating }, (_, index) => (
                      <i className="far fa-star" key={index}></i>
                    ))}
                  </div>
                </div>
                <div className="client-comment">
                  <p>{review.review}</p>
                </div>
              </div>
            ))}
        </div>
      </section>
      </div>
      <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton style={{ backgroundColor: '#7D3807', color: '#fff' }}>
              <Modal.Title>Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{ phoneNumber: '' }}
                onSubmit={(values, actions) => {
                  setPhoneNumber(values.phoneNumber);
                  sendOTP(values.phoneNumber);
                  handleClose();
                  actions.setSubmitting(false);
                  handleShowVerifyModal();
                }}
              >
                {(formikProps) => (
                  <Form onSubmit={formikProps.handleSubmit}>
                    <Form.Group controlId="formBasicPhoneNumber">
                      <Form.Label>Phone Number</Form.Label>
                      <div style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', width: '40px', height: 'auto', display:"flex", alignItems:"center", justifyContent:"start"}}>
                          <img src={indiaFlag} alt="India Flag"  />
                          <p className='mx-1' style={{margin:"auto 0"}}>+91</p>
                        </div>
                        
                        <Form.Control
                          type="tel"
                          name="phoneNumber"
                          value={formikProps.values.phoneNumber}
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          style={{ border: '1px solid #7D3807', borderRadius: '5px', paddingLeft: '85px', marginBottom: "10px", fontSize:"16px" }} 
                        />
                      </div>
                      <Form.Text className="text-muted fs-6">
                        We'll never share your phone number with anyone else.
                      </Form.Text>
                    </Form.Group>
                    <Button
                      className='my-3'
                      variant="primary"
                      type="submit"
                      style={{ backgroundColor: '#7D3807', float: "right", borderColor: '#7D3807', color: "#fff" }}
                    >
                      Send OTP
                    </Button>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
          <Modal show={showVerifyModal} onHide={handleCloseVerifyModal} centered>
          <Modal.Header closeButton style={{ backgroundColor: '#7D3807', color: '#fff' }}>
              <Modal.Title>Verify OTP</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Formik
            initialValues={{ otp: '' }}
            onSubmit={(values, actions) => {
              const otp = values.otp;
              verifyOTP(otp);
              actions.setSubmitting(false);
            }}
          >
            {(formikProps) => (
              <Form onSubmit={formikProps.handleSubmit}>
                <h6 style={{ color: "#000", margin: "10px 0px 20px 0px" }}>Please enter OTP sent to your mobile number.</h6>
                <div style={{ display: 'flex', justifyContent: 'center', gap: "5px", margin: "10px 0px" }}>
                  <OtpInput
                    value={formikProps.values.otp}
                    onChange={(otp) => {
                      formikProps.setFieldValue('otp', otp);
                    }}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    inputStyle={{ fontSize: "16px" }}
                    renderInput={(props) => <input {...props} className='otp-input' />}
                  />
                </div>
                <p>Didn't receive OTP? <a onClick={() => handleResendOTP(formikProps)} href="#">Resend OTP</a></p>
                <Button
                  className='my-3'
                  variant="primary"
                  type="submit"
                  style={{ backgroundColor: '#7D3807', float: "right", borderColor: '#7D3807', color: "#fff" }}
                >
                  Verify OTP
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        </Modal>
    </>
  );
}

export default AstrologerInnerp;
