import React from 'react';
import { useLocation } from 'react-router-dom';
import Navigation from './Navigation';
import Footer from './Footer';

const Numeroo = () => {
    const location = useLocation();
    const { responses } = location.state as any;
    const firstResponseData = responses[0].data;

    const renderRows1 = () => {
        return Object.keys(firstResponseData).map((key) => (
          <tr key={key}>
            <td className='capital' data-label="Title">{key}</td>
            <td className='capital' data-label="Description">{firstResponseData[key]}</td>
          </tr>
        ));
    };

    return (
        <> 
            <div style={{paddingBottom:"100px", marginTop:"0px"}} className="container yoyo">
                <h1 style={{ textTransform: 'capitalize', color:"orange", fontSize:"50px" }}>Numero</h1>
                <hr style={{
                    width:"100%",
                    position:"relative",
                    zIndex:"-1",
                    border: 0,
                    height: "1px",
                    backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0))"
                }} />
                <table className='w-100 basic'>
                    <tbody>
                        {renderRows1()}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Numeroo;
