import React, { useState, useEffect, CSSProperties } from 'react';
import { useLocation } from 'react-router-dom';
import { IoVideocam, IoCall, IoChatboxEllipses } from "react-icons/io5";
import { CloseOutlined, Height } from '@mui/icons-material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { DatePicker, TimePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import dayjs from 'dayjs';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import toast, { Toaster } from 'react-hot-toast';

interface Option {
  label: string;
  value: {
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
    };
    place_id: string;
  };
}


const overlayStyle: CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 999,
  opacity: 0,
  visibility: 'hidden',
  transition: 'opacity 0.3s, visibility 0.3s',
};

const activeOverlayStyle: CSSProperties = {
  opacity: 1,
  visibility: 'visible',
};

const contentStyle: CSSProperties = {
  backgroundColor: '#fff',
  padding: '10px',
  borderRadius: '5px',
  width: '85%',
  height: '70%',
  display:"flex",
  flexWrap:"wrap",
  alignContent:"flex-start",
  overflowY: 'auto',
};

const contentStyle1: CSSProperties = {
  backgroundColor: '#fff',
  padding: '40px 40px',
  borderRadius: '5px',
  width: '70%',
  justifyContent:"center",
  textAlign:"center",
  height: '70%',
  overflowY: 'auto',
};

const AstrologerInner = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [selectedServiceCost, setSelectedServiceCost] = useState(0);
  const [selectedService, setSelectedService] = useState('');
  const [selectedHour, setSelectedHour] = useState<number>(0);
  const [selectedMinute, setSelectedMinute] = useState<number>(0);
  const [selectedDay, setSelectedDay] = useState<number>(0);
  const [selectedMonth, setSelectedMonth] = useState<number>(0);
  const [selectedYear, setSelectedYear] = useState<number>(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<{ lat: string; lon: string; } | null>(null);
  const [selectedTimezone, setSelectedTimezone] = useState('+5.30');
  const [timer, setTimer] = useState(5 * 60);

  const location = useLocation();
  const { apiData } = location.state as any;


  const navigateToChats = () => {
    navigate('/customer/chats');
  };

  const navigateToForm = () => {
    navigate('/customer/formivr', {
      state: {
        id: apiData._id,
        price: apiData.voice_call_price
      }
    });
  };
  
  const navigateToChatForm = () => {
    navigate('/customer/formchat', {
      state: {
        id: apiData._id,
        price: apiData.voice_call_price
      }
    });
  };

  return (
    <> 
      <Toaster />
      <div style={{borderRadius:"20px"}} className="as_main_wrapper">
        <section>
          <div className="container">
            <div className="row cta-buttons">
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                <div className="as_blog_box">
                  <div className="as_blog_img">
                    <div className='d-flex flex-row'>
                      <a>
                        <img
                          src={apiData.profile_photo}
                          alt=""
                          className="img-responsive blog-imag"
                        />
                      </a>
                      <div className="ost-cols5 py-4 px-12">
                        <h5 className='mt-3'>{apiData.name}</h5>
                        <p>{apiData.expertise.join(', ')}</p>
                        <p>{apiData.gender}, {apiData.city}</p>
                        <p>Exp - {apiData.experience} yrs</p>
                        <div className='d-flex align-items-center justify-content-center gap-5'>
                          <h5 className='mt-3 cursor-pointer'><IoCall style={{fontSize:"24px", color:"orange", marginTop:"-2px"}} /> - ₹{apiData.voice_call_price}/min</h5>
                        </div>
                      </div>
                    </div>                      
                    <div className="cta-buttonss as-btn">
                    {apiData.is_active ? (
                          <>
                            <button onClick={navigateToForm} title='Make a Call'> <img src="/images/call.png" className="btn-ico mx-2" alt="" /> Call </button>
                            <button onClick={navigateToChatForm} title='Start a Chat'> <img src="/images/chat.png" className="btn-ico mx-2" alt="" /> Chat </button>

                          </>
                      ) : (
                          <>
                            <button style={{pointerEvents:"none", opacity:"40%"}} title='Make a Call'> <img src="/images/call.png" className="btn-ico mx-2" alt="" /> Call </button>
                            <button style={{pointerEvents:"none", opacity:"40%"}} title='Start a Chat'> <img src="/images/chat.png" className="btn-ico mx-2" alt="" /> Chat </button>
                          </>
                      )}
                      
                    </div>
                  </div>
                  <div className="as_blog_detail">
                    <h3 className="as_subheading">
                      <a href="javascript:;">About.</a>
                    </h3>
                    <p className="as_font14 as_margin0">{apiData.bio}</p>
                    <div className="as_padderTop30"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="testimonials">
        <div className="testimonial-heading">
            <h4>Reviews</h4>
        </div>
        <div className="testimonial-box-container">
          
        {apiData.reviews.slice(0, 5).map((review: any) => (
          <div style={{background:"#fff"}} className="testimonial-box" key={review._id}>
            <div className="box-top">
              <div className="profile">
                <div className="profile-img">
                  <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" alt="Profile" />
                </div>
                <div className="name-user">
                  <strong>{review.customerName}</strong>
                  <span>@{review.customerName.toLowerCase().replace(/\s/g, '')}</span>
                </div>
              </div>
              <div className="reviews">
                {Array.from({ length: review.rating }, (_, index) => (
                  <i className="fas fa-star" key={index}></i>
                ))}
                {Array.from({ length: 5 - review.rating }, (_, index) => (
                  <i className="far fa-star" key={index}></i>
                ))}
              </div>
            </div>
            <div className="client-comment">
              <p>{review.review}</p>
            </div>
          </div>
        ))}

        </div>
      </section>
      </div>
    </>
  );
}

export default AstrologerInner;
