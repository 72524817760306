/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { Header } from './Header'
import { Navbar } from './Navbar'
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie'
import axiosInstance from '../../../../app/helpers/axiosInstance'
import React, { useEffect, useState } from 'react';
import axios from 'axios'


export function HeaderWrapper({ role }: { role: string }) {
  const { config, classes } = useLayout();
  const [profile, setProfile] = useState<any>({}); // Update the type accordingly
  const [userType, setUserType] = useState<string | undefined>('');
  
  const { data } = profile || {}; 
  const user_type = Cookies.get('user_type');

  if (!config.app?.header?.display) {
    return null;
  }
  
  return (
    <div id='kt_app_header' className='app-header'>
      <Toaster />
      <div
        id='kt_app_header_container'
        style={{paddingTop:"10px"}}
        className={clsx(
          'app-container flex-lg-grow-1',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )}
      >       

        <div
          id='kt_app_header_wrapper'
          className='d-flex align-items-stretch justify-content-between flex-lg-grow-1 w-100'
        >
          <img className='nav-img' src="/images/logotext.webp" alt="" />
          <div style={{marginLeft:"40px"}} className="as_menu">
          </div>
          {config.app.header.default?.content === 'menu' &&
            config.app.header.default.menu?.display && (
              <div
                className='app-header-menu app-header-mobile-drawer align-items-stretch'
                data-kt-drawer='true'
                data-kt-drawer-name='app-header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width='225px'
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                <Header />
              </div>
            )}
          <Navbar />
        </div>
      </div>
    </div>
  )
}