import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer';
import Aboutsection from '../../components/aboutsection';
import Navigation from '../../components/Navigation';

const About = () => {
  const location = useLocation();  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <> 
    <div className="as_main_wrapper as_about_page">
      <Navigation activePage="/about" />
      <section className="as_breadcrum_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1>About us</h1>

              <ul className="breadcrumb">
                <li><a href="/">Home</a></li>
                <li>About Us</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Aboutsection />
      <Footer />
    </div>
    </>
  )
}

export default About;