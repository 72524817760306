import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import Navigation from './Navigation';
import Footer from './Footer';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormikHelpers } from 'formik';

const inputStyles = {
  borderRadius: '20px',
  boxShadow: 'none',
  border: '1px solid #f1991c',
  padding: '13px 40px 13px 20px',
  outline: '0'
};
const Numeroform = () => {
    const [selectedLocation, setSelectedLocation] = useState<{ lat: string; lon: string; } | null>(null);
    const [selectedTimezone, setSelectedTimezone] = useState('+5.30');
    const [selectedHour, setSelectedHour] = useState<number>(0);
    const [selectedMinute, setSelectedMinute] = useState<number>(0);
    const [selectedDay, setSelectedDay] = useState<number>(0);
    const [selectedMonth, setSelectedMonth] = useState<number>(0);
    const [selectedYear, setSelectedYear] = useState<number>(0);
    const navigate = useNavigate();
    const location = useLocation();
    const [responses, setResponses] = useState<any[]>([]);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
  
  
    const handleLocationSelect = async (selectedPlace) => {
      try {
        const geocodeResult = await geocodeByPlaceId(selectedPlace.value.place_id);
        const latLng = await getLatLng(geocodeResult[0]);
        const { lat, lng } = latLng;
        setSelectedLocation({ lat: String(lat), lon: String(lng) });
      } catch (error) {
        console.error('Error while fetching coordinates:', error);
      }
    };
  
    const handleTimeChange = (e) => {
      const selectedTime = e.target.value;
      const [hour, minute] = selectedTime.split(':').map(Number);
      setSelectedHour(hour);
      setSelectedMinute(minute);
    };
  
    const handleDateChange = (e) => {
      const selectedDate = e.target.value;
      const [year, month, day] = selectedDate.split('-').map(Number);
      setSelectedYear(year);
      setSelectedMonth(month);
      setSelectedDay(day);
    };
  
    const handleSubmit1 = async (values: {
      full_name: string;
      time_of_birth: Date | null;
      date_of_birth: Date | null;
      place_of_birth: string;
      gender: string;
    }, formikHelpers: FormikHelpers<{
      full_name: string;
      time_of_birth: Date | null;
      date_of_birth: Date | null;
      place_of_birth: string;
      gender: string;
    }>) => {
      try {
        const missingFields: string[] = [];
        if (!values.full_name) {
          missingFields.push('Full Name');
        }
    
        if (!values.gender) {
          missingFields.push('Gender');
        }
        
        if (missingFields.length > 0) {
          const missingFieldsMessage = missingFields.join(', ');
          toast.error(`Please fill in the following fields: ${missingFieldsMessage}`);
          return;
        }
        
        const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdXBlcl9hZG1pbl91c2VyX2lkIjpudWxsLCJhc3Ryb2xvZ2VyX3VzZXJfaWQiOm51bGwsImN1c3RvbWVyX3VzZXJfaWQiOiI2NWU2YmE4MDRkNzA4NjUxZjc4NTVmNjQiLCJpYXQiOjE3MTQ1ODYyNDB9.QI3_XqN6wu7cSxQoGWdHkwB8KmDhYmUbEC-x87xKFT0";
    
        const payload = {
          name: values.full_name,
          day: selectedDay,
          hour: selectedHour,
          min: selectedMinute,
          month: selectedMonth,
          year: selectedYear,
          lat: selectedLocation?.lat,
          lon: selectedLocation?.lon,
          gender: values.gender,
          tzone: selectedTimezone
        };
    
        const allRoutes = [
          'https://dev.astropath.co.in/kundali/backend/numero_table',
        ];

        const axiosRequests = allRoutes.map(async (route) => {
          try {
            const response = await fetch(route, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              },
              body: JSON.stringify(payload),
            });
            const data = await response.json();
            return data;
          } catch (error) {
            console.error(`Error in request to ${route}:`, error);
            throw error;
          }
        });
    
        const responses = await Promise.all(axiosRequests);
  
        navigateToDetails(responses);
    
      } catch (error) {
        toast.error('An error occurred. Please try again later.');
        console.error('Error:', error);
      }
    };
  
  
    const navigateToDetails = (responses) => {
      navigate('/Numero', { state: { responses: responses } });
    };
  

return (
    <> 
      <main>
        <Navigation activePage='' />
        <div style={{marginTop:"100px", marginBottom:"50px"}} className="pivc col-lg-8 col-12 mx-auto">
          <Formik
            initialValues={{
                full_name: '',
                time_of_birth: null as Date | null,
                date_of_birth: null as Date | null,
                place_of_birth: '',
                gender: '',
            }}
            onSubmit={handleSubmit1}
          >
            <Form className="custom-form ebook-download-form bg-white shadow">
              <div className="text-center mb-5">
                <h1 style={{color:"#000"}} className="mb-1">Numero Data</h1>
              </div>
              <p className="mb-5">
                The form solicits user input such as name, gender, date, and place of birth to generate a Numerology. Once submitted, the data is processed to create a Kundali chart, providing insights into various astrological aspects based on the user's details.</p>
              <div className="ebook-download-form-body">
                <div style={{width:"90%"}} className="form-group mb-4">
                    <label className='form-label fs-5 required'>Full Name</label>
                    <Field style={inputStyles} type="text" name="full_name" className="form-control" placeholder="Full Name" />
                    <ErrorMessage name="full_name" component="div" className="error" />
                </div>
                <div style={{width:"90%"}} className="form-group mb-4">
                  <label className='form-label fs-5 required'>Gender</label>
                  <Field style={inputStyles} as="select" name="gender" className="form-control">
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Field>
                  <ErrorMessage name="gender" component="div" className="error" />
                </div>
                <div style={{width:"90%"}} className="form-group mb-4">
                  <label className='form-label fs-5 required'>Time of Birth</label>
                  <Field style={inputStyles} type="time" name="time_of_birth" className="form-control" onChange={handleTimeChange} />
                  <ErrorMessage name="time_of_birth" component="div" className="error" />
                </div>
                <div style={{width:"90%"}} className="form-group mb-4">
                <label className='form-label fs-5 required'>Date of Birth</label>
                  <Field style={inputStyles} type="date" name="date_of_birth" className="form-control" onChange={handleDateChange} />
                  <ErrorMessage name="date_of_birth" component="div" className="error" />
                </div>
                <div className=" mb-4">
                <label className='form-label fs-5 required'>Place of Birth</label>
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyCnkeFp8a1S0S5HHfxMg02JSmL8Sokasno"
                    autocompletionRequest={{ types: ['(regions)'] }}
                    selectProps={{
                      name: 'place_of_birth',
                      placeholder: 'Enter your place of birth',
                      onChange: (selectedPlace) => handleLocationSelect(selectedPlace),
                      className: 'custom-google-places-input'
                    }}
                  />
                  <ErrorMessage name="place_of_birth" component="div" className="error" />
                </div>
                <div className="col-lg-8 col-md-10 col-8 mx-auto">
                  <button style={{width:"100%"}} type="submit" className="btn">Submit</button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
        <Footer />
      </main>
    </>
  );
}

export default Numeroform;
