import React, { useState, useEffect, CSSProperties } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

interface ZodiacSign {
  name: string;
  image: string;
}

interface Props {
  handleVisibilityClick: (signName: string) => void;
}

enum Tabs {
    Yesterday,
    Today,
    Tommorow,
    Monthly,
  }

interface Prediction {
    personal_life: string;
    profession: string;
    health: string;
    emotions: string;
    travel: string;
    luck: string;
  }

const HoroscopeComponent: React.FC<Props> = () => {

    const [sunSign, setSunSign] = useState('');
    const [selectedTimezone, setSelectedTimezone] = useState('5.5');
    const [prediction, setPrediction] = useState<Prediction | null>(null);
    const [previousPrediction, setPreviousPrediction] = useState<Prediction | null>(null);
    const [nextPrediction, setNextPrediction] = useState<Prediction | null>(null);
    const [visible, setVisible] = useState(false);
    const [selectedTabs, setSelectedTabs] = useState<Tabs>(Tabs.Today);
    
  const zodiacSigns: ZodiacSign[] = [
    { name: 'Aries', image: '/images/horo12.png' },
    { name: 'Taurus', image: '/images/horo11.png' },
    { name: 'Gemini', image: '/images/horo10.png' },
    { name: 'Cancer', image: '/images/horo9.png' },
    { name: 'Leo', image: '/images/horo8.png' },
    { name: 'Virgo', image: '/images/horo7.png' },
    { name: 'Libra', image: '/images/horo6.png' },
    { name: 'Scorpio', image: '/images/horo5.png' },
    { name: 'Sagittarius', image: '/images/horo4.png' },
    { name: 'Capricorn', image: '/images/horo3.png' },
    { name: 'Aquarius', image: '/images/horo2.png' },
    { name: 'Pisces', image: '/images/horo1.png' }
  ];

  const navigate = useNavigate();

  const navigateToInner = (zodiacSign) => {
    navigate('/horoscope-inner', { state: { selectedSign: zodiacSign } });
  };

  return (
    <>
        <section className="as_horoscope_wrapper as_padderBottom80 as_padderTop80">
        <div className="container">
            <div className="d-flex align-items-center flex-wrap justify-content-center">
              <div className="col-lg-12 col-md-12 text-center">
                  <h1 className="as_heading">Horoscope Forecasts</h1>
                  <p className="as_font14 as_padderTop20 as_padderBottom20">
                    A horoscope is a prediction of a person's future based on their zodiac sign. Horoscope can assist a person in making significant decisions in life and knowing in advance the consequences of an action in their life, It often includes advice on job & career,marriage,relationships, career, and health. Checking your horoscope for yesterday, today, and tomorrow can provide insights into what to expect and how to navigate life's challenges and opportunities.
                  </p>
              </div>
              <div className="d-flex align-items-center px-2 flex-wrap justify-content-between">
                {zodiacSigns.map((sign, index) => (
                    <div key={index} className="col-lg-2 col-sm-2 col-xs-2">
                    <div className="as_sign_box text-center cursor-pointer">
                    <a onClick={() => navigateToInner(sign.name)}>
                        <span className="as_sign">
                            <img src={sign.image} alt="" />
                        </span>
                        <div>
                            <h5>{sign.name}</h5>
                        </div>
                        </a>
                    </div>
                    </div>
                ))}
              </div>
            </div>
        </div>
        </section>
    </>
  );
};

export default HoroscopeComponent;
