import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import Loader from '../../components/LoaderHome';
import toast, { Toaster } from 'react-hot-toast';
import useRazorpay from "react-razorpay";

interface Transaction {
    createdAt: string;
    type: string;
    status: string;
    amount: string;
    updatedAt: string;
}

const Wallet = () => {
    const [balance, setBalance] = useState(0);
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [loading, setLoading] = useState(true);
    const [transactionAmount, setTransactionAmount] = useState('');
    const [amount, setAmount] = useState('');
    const [orderId, setOrderId] = useState<string>('');
    const [Razorpay] = useRazorpay();
    useEffect(() => {
        fetchWalletData();
        fetchTransactionData();
    }, []);

    const fetchWalletData = async () => {
        try {
            const token = Cookies.get('token');
            const response = await axios.get('https://dev.astropath.co.in/customer/customer/fetch_wallet_balance', {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            const responseData = response.data.data;
            setBalance(responseData);
        } catch (error) {
            console.error('Error fetching Balance data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchTransactionData = async () => {
        try {
            const token = Cookies.get('token');
            const response = await axios.get('https://dev.astropath.co.in/customer/customer/transaction', {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            const responseData = response.data;
            setTransactions(responseData);
        } catch (error) {
            console.error('Error fetching Transaction data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTransactionAmount(event.target.value);
    };  

    const handlePayment = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const token = Cookies.get('token');
            if (token) {
                const response = await axios.post('https://dev.astropath.co.in/customer/customer/create_payment_order', {
                    amount: transactionAmount
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.data && response.status == 200) {
                    const orderId = response.data.response.id;
                    setOrderId(orderId);
                    const amount = response.data.response.amount;
                    setAmount(amount)
                    startPayment(orderId, parseFloat(amount), event);
                } else {
                    console.error('Error creating payment order:', response);
                }
            } else {
                console.error('Token not found.');
            }
        } catch (error) {
            console.error('Error handling payment:', error);
        }
    };
    
    

    const startPayment = (orderId, amount, event) => {
        var options = {
            description: 'Add To Wallet',
            image: 'https://firebasestorage.googleapis.com/v0/b/zegocloudvideocall-b50bd.appspot.com/o/userprofile%2FWhatsApp%20Image%202024-04-03%20at%2012.55.45%20PM.jpeg?alt=media&token=729aae36-a285-4ccb-802d-ea512cbadb07',
            currency: 'INR',
            key: process.env.REACT_APP_RAZORPAY_KEY || '',
            amount: amount,
            name: 'Astropath',
            order_id: orderId,
            prefill: {
                email: 'tanmaysingh@gmail.com',
                name: 'Tanmay',
            },
            handler: function (response) {
                if (response.razorpay_payment_id && response.razorpay_order_id && response.razorpay_signature) {
                    toast.success("Payment successful!");
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            },
            theme: { color: '#FFCC00' },
        };
    
        const rzp1 = new Razorpay(options);
    
        rzp1.on("payment.failed", function (response) {
            toast.error("Payment failed: " + response.error.description);
        });
    
        rzp1.open();
        event.preventDefault();
    };
    
    
    

    return (
        <div className="">
            <Toaster />
            <section>
                <div className="container">
                    <div className="row cta-buttons">
                        <div className="d-flex flex-column col-xl-4 col-lg-4">
                            <div className="card l-bg-orange-dark">
                                <div className="card-statistic-3 p-4">
                                    <div className="card-icon card-icon-large"><i className="fas fa-dollar-sign"></i></div>
                                    <div className="mb-4">
                                        <h5 className="card-titles mb-0">Wallet Balance</h5>
                                    </div>
                                    <div className="row align-items-center justify-content-between mb-2 d-flex">
                                        <div className="col-6">
                                            <h3 style={{color:"#fff"}} className="d-flex align-items-center mb-0">
                                                ₹ {new Intl.NumberFormat('en-IN').format(balance)} 
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form className='hello' onSubmit={handlePayment}> {/* Attach handlePayment to onSubmit */}
                                <input style={{width:"200px", marginRight:"20px", borderRadius:"10px", border:"0.5px solid #dadada", padding:"7px 10px", marginTop:"13px"}} type="number" value={transactionAmount} onChange={handleAmountChange} placeholder="Enter Amount" />
                                <button type="submit" className='col-4 bal-but'>Add Balance</button> {/* Remove onClick from button */}
                            </form>
                            <div style={{width:"100%", background:"#fff"}} className='astro-cont4'>
                                <p>Recharge Now and Get</p>
                                <h1 style={{color:"#f1991c", margin:"0 auto", fontSize:"50px"}}>30%</h1>
                                <h3 className='mt-3'>Extra Topup Balance</h3>
                            </div>

                        </div>                        
                        <section className='w-75 card my-5 '>
                            <div className='card-header'>
                                <h3 className='card-title align-content-start flex-row'>
                                    <span className='card-label text-gray-600 fw-bold fs-3 mt-4 '>Recent Transactions</span>
                                </h3>
                            </div>
                            <hr />
                            <div className='card-body py-2'>
                                {loading ? (
                                    <Loader loading={loading} />
                                ) : (
                                    <div className='table-responsive'>
                                        <table className='table table-row-bordered table-row-gray-300 align-middle gs-0 gy-3'>
                                            <thead>
                                                <tr className='fw-bold '>
                                                    <th className='fs-5 min-w-100px'>Date</th>
                                                    <th className='fs-5 min-w-100px'>Type</th>
                                                    <th className='fs-5 min-w-100px'>Status</th>
                                                    <th className='fs-5 min-w-100px'>Amount</th>
                                                    <th className='fs-5 min-w-100px'>Last Updated</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {transactions.map((transaction, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <a className='text-gray-600 fw-bold text-hover-primary fs-6'>
                                                                {new Date(transaction.createdAt).toLocaleDateString()}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href='#' className='text-muted text-hover-primary d-block mb-1 fs-6'>
                                                                {transaction.type}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href='#' className='text-muted text-hover-primary d-block mb-1 fs-6'>
                                                                {transaction.status}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href='#' className='text-muted text-hover-primary d-block mb-1 fs-6'>
                                                                ₹ {new Intl.NumberFormat('en-IN').format(Number(transaction.amount))}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href='#' className='text-muted text-hover-primary d-block mb-1 fs-6'>
                                                                {new Date(transaction.updatedAt).toLocaleDateString()}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Wallet;
