import React, { useState, ReactElement } from 'react';

const Footer = () => {
  
  return (
    <> 
      <section className="as_footer_wrapper as_padderTop80">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">

              <div className="as_footer_inner as_padderTop10 as_padderBottom40">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="as_footer_widget">
                      <div className="mb-4 as_footer_logo">
                        <a href="/"
                          ><img src="/images/logos11.png" width="250" height="100" alt=""
                        /></a>
                      </div>
                      <h3 className="mb-3">
                        PKM Tech Pvt. Ltd.
                      </h3>

                      <ul className="as_contact_list">
                        <li>
                          <img src="/images/svg/map.svg" alt="" />
                          <p>982, Sector 15, Vasundhra, Ghaziabad, 201012 Uttar Pradesh</p>
                        </li>
                        <li>
                          <img src="/images/svg/phone.svg" alt="" />
                          <p>
                            <a href="javascript:;">+91 8527-200-764</a>
                          </p>
                        </li>
                        <li>
                          <img src="/images/svg/mail.svg" alt="" />
                          <p>
                            <a href="javascript:;">info@astropath.com</a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 footer-align">
                    <div className="as_footer_widget">
                      <h3 className="as_footer_heading">Quick Links</h3>

                      <ul>
                        <li><a href="/account-deletion"> Account Deletion</a></li>
                        <li><a href="/contact"> Contact Us</a></li>
                        <li><a href="/terms-and-conditions"> Terms & Conditions</a></li>
                        <li><a href="/privacy-policy"> Privacy Policy</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 footer-align">
                    <div className="as_footer_widget">
                      <h3 className="as_footer_heading">Social Links</h3>

                      <ul>
                        <li>
                          <a href="https://www.instagram.com/astropath.co.in"> Instagram</a>
                        </li>
                        <li>
                          <a href="https://www.facebook.com/profile.php?id=61556794373038&mibextid=ZbWKwL"> Facebook</a>
                        </li>
                        <li>
                          <a href="https://twitter.com/Astropath24">Twitter</a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/@Astropath-"> Youtube</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 footer-align">
                    <div className="as_footer_widget">
                      <h3 className="as_footer_heading lst-he">Also available in</h3>
                      <div className="as_login_data booton">
                        <a href="https://play.google.com/store/apps/details?id=com.astropath&pli=1" target="_blank" className="market-btn google-btn" role="button">
                          <span className="market-button-title">Google Play</span>
                        </a>
                        <label
                          >Click on the app and connect to astrologers via our app
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="as_copyright_wrapper text-center">
                <p>Copyright &copy; 2024 Astropath. All Right Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer;