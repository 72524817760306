import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.height = '100%';
    }
    return () => {
      if (root) {
        root.style.height = 'auto';
      }
    };
  }, []);

  return (
    <div className='d-flex flex-column flex-lg-row-reverse flex-column-fluid h-100'>
      <div
        className='d-flex flex-column flex-lg-row-fluid w-100 order-2 order-lg-1'
        style={{ overflowY: 'auto', maxHeight: '100vh' }}
      >
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-100'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export { AuthLayout };
