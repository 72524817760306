
import React, { useState, CSSProperties } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import { CloseOutlined, Height } from '@mui/icons-material';
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FormikHelpers } from 'formik';

const overlayStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.3s, visibility 0.3s',
  };
  
  const activeOverlayStyle: CSSProperties = {
    opacity: 1,
    visibility: 'visible',
  };
  
  const contentStyle: CSSProperties = {
    backgroundColor: '#fff',
    padding: '10px',
    borderRadius: '5px',
    width: '85%',
    height: '70%',
    display:"flex",
    flexWrap:"wrap",
    alignContent:"flex-start",
    overflowY: 'auto',
  };
  
  const contentStyle1: CSSProperties = {
    backgroundColor: '#fff',
    padding: '40px 40px',
    borderRadius: '5px',
    width: '70%',
    justifyContent:"center",
    textAlign:"center",
    height: '70%',
    overflowY: 'auto',
  };

const inputStyles = {
  borderRadius: '20px',
  boxShadow: 'none',
  border: '1px solid #f1991c',
  padding: '13px 40px 13px 20px',
};
const Formchat = () => {
    const [selectedLocation, setSelectedLocation] = useState<{ lat: string; lon: string; } | null>(null);
    const [selectedHour, setSelectedHour] = useState<number>(0);
    const [selectedMinute, setSelectedMinute] = useState<number>(0);
    const [selectedDay, setSelectedDay] = useState<number>(0);
    const [selectedMonth, setSelectedMonth] = useState<number>(0);
    const [selectedTimezone, setSelectedTimezone] = useState('+5.30');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [selectedService, setSelectedService] = useState('ivr');
    const [selectedYear, setSelectedYear] = useState<number>(0);
    const navigate = useNavigate();
    const location = useLocation();  
    const { id, price } = location.state as any || {};

      const handleTimeChange = (e) => {
        const selectedTime = e.target.value;
        const [hour, minute] = selectedTime.split(':').map(Number);
        setSelectedHour(hour);
        setSelectedMinute(minute);
      };
    
      const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        const [year, month, day] = selectedDate.split('-').map(Number);
        setSelectedYear(year);
        setSelectedMonth(month);
        setSelectedDay(day);
      };

      const handleLocationSelect = async (selectedPlace) => {
        try {
          const geocodeResult = await geocodeByPlaceId(selectedPlace.value.place_id);
          const latLng = await getLatLng(geocodeResult[0]);
          const { lat, lng } = latLng;
          setSelectedLocation({ lat: String(lat), lon: String(lng) });
        } catch (error) {
          console.error('Error while fetching coordinates:', error);
        }
      };

      const navigateToChats = () => {
        navigate('/customer/chats');
      };

      const handleSubmit = async (values: {
        full_name: string;
        time_of_birth: Date | null;
        date_of_birth: Date | null;
        place_of_birth: string;
        gender: string; }, formikHelpers: FormikHelpers<{
        full_name: string;
        time_of_birth: Date | null;
        date_of_birth: Date | null;
        place_of_birth: string;
        gender: string;
      }>) => {
     
        try {
          const missingFields: string[] = [];
          if (!values.full_name) {
            missingFields.push('Full Name');
          }
          if (!values.gender) {
            missingFields.push('Gender');
          }
          
          if (missingFields.length > 0) {
            const missingFieldsMessage = missingFields.join(', ');
            toast.error(`Please fill in the following fields: ${missingFieldsMessage}`);
            return;
          }
          const token = Cookies.get('token');
          
          const kundaliData = {
            name: values.full_name,
            day: selectedDay,
            hour: selectedHour,
            min: selectedMinute,
            month: selectedMonth,
            year: selectedYear,
            lat: selectedLocation?.lat,
            lon: selectedLocation?.lon, 
            timeZone: selectedTimezone,       
          };
          const { lat, lon } = kundaliData;
          if (!lat || !lon) {
            missingFields.push('Place of Birth');
          }
          const response = await fetch('https://dev.astropath.co.in/customer/customer/request-token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                astrologerId: id,
                service_cost: price,
                services: 'ivr',
                kundali_data: kundaliData,
              }),
          });
        
          const data = await response.json();
            if (data.success === 1) {
                toast.success('Astrologer Selected Successfully !!\nYou will be redirected to chat page', {
                style: {
                    background: '#fff',
                    color: '#000',
                },
                icon: <img src="https://png.pngtree.com/png-vector/20230105/ourmid/pngtree-d-green-check-mark-icon-in-round-isolated-transparent-background-tick-png-image_6552327.png" alt="Not Found" style={{ width: '70px', height: '70px' }} />,
                });
                setTimeout(() => {
                window.location.href = '/customer/chats';
                }, 4000);
                navigateToChats();
            } else if (data.msg === "Insufficient balance") {
                toast.custom((t) => (
                <div className={`toast show`} role="alert" aria-live="assertive" aria-atomic="true">
                    <div className="toast-header">
                    <h2 className='me-auto'>Insufficient Funds</h2>
                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => toast.dismiss(t.id)}></button>
                    </div>
                    <div className="toast-body d-flex justify-content-between">
                        <p className='my-2'>Must Have at least 5min of Balance</p>
                        <button className='btn btn-primary'><Link to="/customer/transactions">+ Add</Link></button>
                    </div>
                </div>
            ), {
                duration: Infinity
            });
            } else {
                toast.error(data.msg);
            }
            } catch (error) {
            toast.error('An error occurred. Please try again later.');
            console.error('Error:', error);
            }    
        };
  
          const handleCloseClick1 = () => {
            setShowSuccessModal(false);
          };

return (
    <> 
      <main>
        <div style={{marginBottom:"50px"}} className="col-lg-8 col-12 mx-auto">
          <Formik
            initialValues={{
                full_name: '',
                time_of_birth: null as Date | null,
                date_of_birth: null as Date | null,
                place_of_birth: '',
                gender: '',
            }}
            onSubmit={handleSubmit}
          >
            <Form className="custom-form ebook-download-form bg-white shadow">
              <div className="text-center mb-5">
                <h1 style={{color:"#000"}} className="mb-1">Kundli Data</h1>
              </div>
              <p className="mb-5">
                The form solicits user input such as name, gender, date, and place of birth to generate a Kundali. Once submitted, the data is processed to create a Kundali chart, providing insights into various astrological aspects based on the user's details, so that astrologers can provide insights about the user</p>
              <div className="ebook-download-form-body">
                <div  style={{width:"90%"}} className="form-group mb-4">
                  <label className='form-label fs-5 required'>Full Name</label>
                    <Field type="text" name="full_name" placeholder="Name" style={inputStyles} className="form-control" />
                </div>
                <div style={{width:"90%"}} className="form-group mb-4">
                <label className='form-label fs-5 required'>Gender</label>
                  <Field style={inputStyles} as="select" name="gender" className="form-control">
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Field>
                  <ErrorMessage name="gender" component="div" className="error" />
                </div>
                <div style={{width:"90%"}} className="form-group mb-4">
                  <label className='form-label fs-5 required'>Time of Birth</label>
                  <Field style={inputStyles} type="time" name="time_of_birth" className="form-control" placeholder="hh:mm" onChange={handleTimeChange} />
                  <ErrorMessage name="time_of_birth" component="div" className="error" />
                </div>
                <div style={{width:"90%"}} className="form-group mb-4">
                  <label className='form-label fs-5 required'>Date of Birth</label>
                  <Field style={inputStyles} type="date" name="date_of_birth" className="form-control" placeholder="dd-mm-yyyy" onChange={handleDateChange} />
                  <ErrorMessage name="date_of_birth" component="div" className="error" />
                </div>

                <div className=" mb-4">
                <label className='form-label fs-5 required'>Place of Birth</label>
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyCnkeFp8a1S0S5HHfxMg02JSmL8Sokasno"
                    autocompletionRequest={{ types: ['(regions)'] }}
                    selectProps={{
                      name: 'place_of_birth',
                      placeholder: 'Enter your place of birth',
                      onChange: (selectedPlace) => handleLocationSelect(selectedPlace),
                      className: 'custom-google-places-input'
                    }}
                  />
                  <ErrorMessage name="place_of_birth" component="div" className="error" />
                </div>
                <div className="col-lg-8 col-md-10 col-8 mx-auto">
                  <button style={{width:"100%"}} type="submit" className="btn">Submit</button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </main>
      {showSuccessModal && (
          <div className="overlay" style={{ ...overlayStyle, ...(showSuccessModal && activeOverlayStyle) }}>
            <div style={contentStyle}>
              <div onClick={() => handleCloseClick1()} style={{ backgroundColor: '#d3d3d3', padding: "9px 11px", position: "absolute", top: "14vh", right: "5vw", transform: "translate(-35%, -40%)", borderRadius: 20, cursor: 'pointer' }}>
                <CloseOutlined />
              </div>
              <h1 style={{ color: "orange", fontSize: "5vh", paddingLeft: "15px" }} className='font-bold mx-auto'>Order Confirmed</h1>
              <hr
                style={{
                  width: '100%',
                  borderTop: '2px dashed #7D3807',
                  borderBottom: '1px solid transparent',
                  margin: '10px',
                  background: "transparent",
                }}
              />
              <p>
                Your order for a consultation with an astrologer has been confirmed! Get ready for an insightful conversation that could illuminate your path. You'll receive a call from one of the following numbers: 01207176839, 01205166091, or 911204047396. Please ensure you're available to take the call. Prepare any questions or topics you'd like to discuss during the consultation. This session could provide valuable insights into your life's journey. Stay attentive, and make the most of this opportunity to gain clarity and guidance from a seasoned astrologer. We're excited to facilitate this enriching experience for you! <br />
                You can now leave this page and wait for astrologer to call you
              </p>
            </div>
          </div>
        )}
    </>
  );
}

export default Formchat;
