import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';
import { lazy, FC, Suspense } from 'react'
import './style.css';
import Footer from '../../components/Footer';
import Cookies from 'js-cookie';
import HoroscopeComponent from '../../components/Horoscope';
import Aboutsection from '../../components/aboutsection';
import Navigation from '../../components/Navigation';
import { Link, useNavigate } from 'react-router-dom';
import Astro from '../../components/astro';
import axios, { CancelTokenSource } from 'axios';

type Props = {
  astrologerData: any;
};

interface ZodiacSign {
  name: string;
  image: string;
  to: string;
}

enum Tabs {
  Yesterday,
  Today,
  Tomorrow,
  Monthly,
}

interface Prediction {
  personal_life: string;
  profession: string;
  health: string;
  emotions: string;
  travel: string;
  luck: string;
}

interface Astrologer {
  _id: string;
  profile_photo: string;
  name: string;
  averageRating: number;
  totalCount: number;
  is_active: boolean;
  expertise: string[];
  language?: string | null;
  chat_price: number;
}

type State = {
  show: boolean;
  showMenu: boolean;
  visible: boolean;
  astrologerDataa: Astrologer | null;
  selectedTabs: Tabs;
  sunSign: string;
  selectedTimezone: string;
  prediction: Prediction | null;
  previousPrediction: Prediction | null;
  nextPrediction: Prediction | null;
  astrologersf: Astrologer[];
  loading: boolean;
};

type Action =
  | { type: 'SET_VISIBLE'; payload: boolean }
  | { type: 'SET_PREDICTION'; payload: { sunSign: string; previousPrediction: Prediction; nextPrediction: Prediction; prediction: Prediction } }
  | { type: 'SET_LOADING'; payload: boolean };

const initialState: State = {
  show: false,
  showMenu: false,
  visible: false,
  astrologerDataa: null,
  selectedTabs: Tabs.Today,
  sunSign: '',
  selectedTimezone: '5.5',
  prediction: null,
  previousPrediction: null,
  nextPrediction: null,
  astrologersf: [],
  loading: true,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_VISIBLE':
      return { ...state, visible: action.payload };
    case 'SET_PREDICTION':
      return {
        ...state,
        sunSign: action.payload.sunSign,
        previousPrediction: action.payload.previousPrediction,
        nextPrediction: action.payload.nextPrediction,
        prediction: action.payload.prediction,
      };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

const Home: React.FC<Props> = ({ astrologerData }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);

  const handleGetData = useCallback(async (zodiacSign: string) => {
    dispatch({ type: 'SET_VISIBLE', payload: true });
    const token = Cookies.get('token');

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      const body = JSON.stringify({
        zodiacName: zodiacSign,
        tzone: state.selectedTimezone,
      });

      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
      }

      cancelTokenSourceRef.current = axios.CancelToken.source();

      const urls = [
        'https://dev.astropath.co.in/kundali/backend/sun_sign_prediction/daily/previous',
        'https://dev.astropath.co.in/kundali/backend/sun_sign_prediction/daily/next',
        'https://dev.astropath.co.in/kundali/backend/sun_sign_prediction/daily',
      ];

      const [previousData, nextData, dailyData] = await Promise.all(
        urls.map((url) =>
          axios.post(url, body, { headers, cancelToken: cancelTokenSourceRef.current!.token }).then((res) => res.data)
        )
      );

      dispatch({
        type: 'SET_PREDICTION',
        payload: {
          sunSign: zodiacSign,
          previousPrediction: previousData.data.prediction,
          nextPrediction: nextData.data.prediction,
          prediction: dailyData.data.prediction,
        },
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Error:', error);
      }
    }
  }, [state.selectedTimezone]);

  useEffect(() => {
    return () => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('Component unmounted');
      }
    };
  }, []);

  return (
    <>
      <div className="">
        <Navigation activePage="/" />

        <section className="as_banner_wrapper">
          <div className="container">
            <div className="row as_verticle_center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="as_banner_slider">
                  <div className="as_banner_detail">
                    <h5>Celestial Insights</h5>
                    <h1>
                      Unlock the map to your <br /> destiny with Astropath
                    </h1>
                    <p>
                    Your guide for life's journey, navigating through challenges and opportunities using astrology. Astropath is India's premier online astrologer platform specializing in Vedic Astrology, Vastu, Palmistry, Numerology, Tarot reading, and Lal Kitab. Seek expert advice and effective remedies for life's challenges, and unlock cosmic insights and solutions.
                    </p>
                    <Link to="/astrologers" className="as_btn21">
                      Talk to Astrologer
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="as_banner_img text-center">
                  <img src="/images/service_img2.webp" alt="" className="img-responsive as_hand_bg" loading="lazy" />
                  <img src="/images/onlylogo.png" alt="" className="img-responsive as_hand" loading="lazy" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mobile px-4 justify-content-between flex-column">
          <h2 className="mt-8">Daily Predictions</h2>
          <div className="d-flex align-items-center flex-wrap justify-content-between">
            {[
              { to: '/kundliform', imgSrc: '/images/kundli.png', text: 'Kundli' },
              { to: '/numeroform', imgSrc: '/images/numero.png', text: 'Numerology' },
              { to: '/panchang', imgSrc: '/images/panchang.png', text: 'Panchang' },
            ].map((item, index) => (
              <div key={index} className="col-lg-2 col-sm-2 col-xs-2">
                <div className="as_sign_box text-center cursor-pointer">
                  <Link to={item.to}>
                    <span className="as_sign">
                      <img src={item.imgSrc} alt={item.text} loading="lazy" />
                    </span>
                    <div>
                      <h5>{item.text}</h5>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>

        <HoroscopeComponent handleVisibilityClick={handleGetData} />

        <Astro astrologerData={astrologerData} />

        <section className="as_service_wrapper as_padderTop80 as_padderBottom80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h1 className="as_heading as_heading_center">our services</h1>
                <p className="as_font14 as_padderTop20 as_padderBottom20">
                  Embark on a transformative journey with our expert astrologers, offering personalized horoscope forecasts, <br /> career guidance, relationship advice, single and matchmaking Kundli services, empowering seekers to embrace their true destiny.
                </p>
              </div>
            </div>

            <div className="row as_verticle_center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="as_service_img">
                  <img src="/images/service_img2.webp" alt="" className="as_service_circle img-responsive" loading="lazy" />
                  <img src="/images/sage.webp" alt="" className="as_service_img img-responsive" loading="lazy" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                  {[
                    { to: '/kundliform', heading: 'Kundli', text: 'Harmonize love and destiny with compatible astrological matches.' },
                    { to: '/numeroform', heading: 'NumeroLogy', text: 'Personalized insights for individual cosmic alignment.' },
                    { to: '/astrologers', heading: 'Relationship Advice', text: 'Illuminate love\'s journey with insightful astrological wisdom.' },
                    { to: '/astrologers', heading: 'Career Guidance', text: 'Navigate career paths with personalized direction.' },
                  ].map((item, index) => (
                    <Link key={index} to={item.to} className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="as_service_box text-center">
                        <h4 className="as_subheading">{item.heading}</h4>
                        <p className="as_paddingBottom10">{item.text}</p>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="as_whychoose_wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12 text-center">
                <h1 className="as_heading">Why Choose Us</h1>
                <p className="as_font14 as_padderTop20 as_padderBottom50">
                  Astropath provides India's best and verified Astrologers who offer personalized horoscope readings, guidance, and insights based on birth charts and planetary positions. Our experts predict and provide remedies for love, relationships, marriage, career, finances, business, education, and other personal matters. Consult Vedic astrologers, Numerologists, Vastu experts, Tarot readers, Palmistry, and Lalkitab specialists 24/7 via call or online chat in English, Hindi, Tamil, Punjabi, and more languages. For accurate readings, clients should share birth details and be open to exploring astrological insights with our astrologers who offer easy and effective remedies.
                </p>
              </div>
              <div className="col-lg-12">
                <ul className="as_choose_ul">
                  {[
                    { number: 100, text: 'Trusted by various Clients' },
                    { number: 3, text: 'Years of Experience' },
                    { number: 5, text: 'Types of Horoscopes' },
                    { number: 100, text: 'Qualified Astrologers' },
                    { number: 99, text: 'Success Horoscope' },
                  ].map((item, index) => (
                    <li key={index}>
                      <div className="as_whychoose_box text-center">
                        <span className="as_number">
                          <span>
                            <span data-from="0" data-to={item.number} data-speed="5000">{item.number}</span>
                          </span>
                          <img src="/images/svg/choose.svg" alt="" />
                        </span>
                        <h4>{item.text}</h4>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default Home;
