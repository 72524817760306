import React, { useState, useEffect } from 'react';
import { HeaderUserMenu } from '../../../partials';
import Cookies from 'js-cookie';
import axios from 'axios';
import defaultImage from '../../../assets/card/favi.png'
import { IoWalletOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link } from 'react-router-dom';

const userAvatarClass = 'symbol-35px';

const Navbar = () => {
    const [profile, setProfile] = useState({
        email: '',
        name: '',
        profile_photo: '',
        balance: ''
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const token = Cookies.get('token');
            const response = await axios.get('https://dev.astropath.co.in/customer/customer/profile', {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            const responseData = response.data;
            setProfile({
                ...responseData,
                balance: Math.round(responseData.balance)
            });
        } catch (error) {
            console.error('Error fetching profile data:', error);
        }
    };

    return (
        <div style={{ marginTop: "10px" }} className='app-navbar flex-shrink-0'>
            <div className='app-navbar-item'>
                <Link to='/customer/transactions' className='d-flex align-items-center' style={{ fontSize: "22px", marginTop: '-20px', color: "#724343", border: "1px solid", padding: "5px 10px", borderRadius: "10px" }} ><IoWalletOutline /><span style={{ fontSize: "16px", marginLeft: "5px" }}> - ₹{profile.balance}</span></Link>
                <div className='cursor-pointer symbol' data-kt-menu-trigger="{default: 'click'}"
                    data-kt-menu-attach='parent' data-kt-menu-placement='bottom-end'>
                    <RxHamburgerMenu style={{ fontSize: "25px", marginTop: '-20px', marginLeft: "-10px", color: "#724343" }} />
                </div>
                <HeaderUserMenu profile={profile} />
            </div>
        </div>
    );
};

export { Navbar };
