import React, { useState, useEffect, CSSProperties } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { CloseOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const overlayStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.3s, visibility 0.3s',
};

const activeOverlayStyle: CSSProperties = {
    opacity: 1,
    visibility: 'visible',
};

const contentStyle: CSSProperties = {
    backgroundColor: '#fff',
    padding: '10px 5px',
    borderRadius: '5px',
    width: '85%',
    height: '80%',
    marginTop:"3vw",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'auto',
};

const Chatss = () => {
    const [chatHistory, setChatHistory] = useState<any>([]);
    const [visible, setVisible] = useState(false);
    const [selectedChat, setSelectedChat] = useState({ astrologerPhone: '', status: '' });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = Cookies.get('token');
                const response = await axios.get('https://dev.astropath.co.in/customer/customer/get_chat_history', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });
                const filteredChats = response.data.data.filter((chat: any) => chat.status === 'In-Progress');
                setChatHistory(filteredChats);
            } catch (error) {
                console.error('Error fetching chat history:', error);
            }
        };

        fetchData();
    }, []);


    const navigateToChat = (astrologerPhone: string, status: string, astrologerProfile: string, astrologerName: string) => {
        navigate('/customer/chatmain', { state: { apiData: { astrologerPhone, status, astrologerProfile, astrologerName} } });
    };

    return (
        <div className="col-lg-12 d-flex justify-content-center">
            <div className="card chat-app">
                <div id="plist" className="people-list">
                <h1 style={{color:"orange"}}>Ongoing Chats</h1>
                    <ul className="list-unstyled chat-list mb-0">
                        {chatHistory.map((chat: any) => (
                            <li onClick={() => navigateToChat(chat.astrologerPhone, chat.status, chat.astrologerProfile, chat.astrologerName)} className="clearfix" key={chat._id}>
                                <img src={chat.astrologerProfile} alt="avatar" />
                                <div className="about">
                                    <div className="name">Talked to - {chat.astrologerName}</div>
                                    <div className="status">Status: {chat.status}</div>
                                    <div className="date">Date: {new Date(chat.date).toLocaleDateString()}</div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Chatss;

