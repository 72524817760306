import React, { useState, useEffect } from 'react';
import Navigation from '../../components/Navigation';
import axios from 'axios';

interface Blog {
    _id: string;
    title: string;
    photo: string;
    description: string;
    createdAt: string;
    tags: [];
}

interface BlogsProps {
    activePage: string;
}
const Blogs = () => {
    const [blogs, setBlogs] = useState<Blog[]>([]);

    useEffect(() => {
        const fetchAllBlogs = async () => {
            try {
                const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdXBlcl9hZG1pbl91c2VyX2lkIjpudWxsLCJhc3Ryb2xvZ2VyX3VzZXJfaWQiOm51bGwsImN1c3RvbWVyX3VzZXJfaWQiOiI2NWU2YmE4MDRkNzA4NjUxZjc4NTVmNjQiLCJpYXQiOjE3MTQ1ODYyNDB9.QI3_XqN6wu7cSxQoGWdHkwB8KmDhYmUbEC-x87xKFT0";
                const response = await axios.get('https://dev.astropath.co.in/customer/customer/fetch_all_blogs', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                setBlogs(response.data.data);
            } catch (error) {
                console.error('Error fetching all blogs:', error);
            }
        };
        fetchAllBlogs();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        
        let dayWithSuffix;
        if (day === "01" || day === "21" || day === "31") {
          dayWithSuffix = `${day}st`;
        } else if (day === "02" || day === "22") {
          dayWithSuffix = `${day}nd`;
        } else if (day === "03" || day === "23") {
          dayWithSuffix = `${day}rd`;
        } else {
          dayWithSuffix = `${day}th`;
        }
      
        return `${dayWithSuffix} ${month} ${year}`;
    };
    

    return (
        <>
            <Navigation activePage='/blogs' />
            <div className="container yoyo py-2">
                <div className="h1 text-center text-dark" id="pageHeaderTitle">Astropath Blogs</div>
                    {blogs.map((blog) => (
                        <article key={blog._id} className="postcard light green">
                            <a className="postcard__img_link" href="#">
                                <img className="postcard__img" src={blog.photo} alt="Image Title" />
                            </a>
                            <div className="postcard__text t-dark">
                                <h1 className="postcard__title green"><a href="#">{blog.title}</a></h1>
                                <div className="postcard__subtitle small">
                                <time>{formatDate(blog.createdAt)}</time>
                                </div>
                                <div className="postcard__bar"></div>
                                <div className="postcard__preview-txt" dangerouslySetInnerHTML={{ __html: blog.description }} style={{ maxHeight: '8em', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 4 }}></div>


                                <ul className="postcard__tagbox">
                                    {blog.tags.map((tag, index) => (
                                        <li key={index} className="tag__item">{tag}</li>
                                    ))}
                                </ul>

                            </div>
                        </article>
                    ))}
                </div>
        </>
    )
}

export default Blogs;
